import React, { useEffect, useState, useRef, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import styles from "./StatusBtn.module.scss";

const StatusBtn = ({ warningIcon, successIcon, title, status = "" }) => {
  return (
    <>
      {status === "Success" ? (
        <span className={`${styles["successBtn"]}`}>
          <img src={successIcon} /> {title}
        </span>
      ) : status === "Warning" ? (
        <span className={`${styles["warningBtn"]}`}>
          <img src={warningIcon} /> {title}
        </span>
      ) : status === "Error" ? (
        <span className={`${styles["errorBtn"]}`}>
          <img src={""} /> {title}
        </span>
      ) : status === "Info" ? (
        <span className={`${styles["infoBtn"]}`}>
          <img src={""} /> {title}
        </span>
      ) : (
        <span className={`${styles["greyBtn"]}`}>
          <img src={""} /> {title}
        </span>
      )}
    </>
  );
};

export default StatusBtn;
