import { React, useEffect, useState } from "react";
import { Button, ButtonGroup, Collapse, Dropdown, Form } from "react-bootstrap";

import UpdateTable from "../modals/UpdateTable";
import Rename from "../modals/Rename";
import Delete from "../modals/Delete";
import DataType from "./DataType";
import "./output.css";
import OutputTableXls from "../tablelist/OutputTableXls";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const OutputXls = (props) => {
  const [open, setOpen] = useState(false);

  const [tablesList, setTablesList] = useState([]);

  function FindIndexInObj(array, Key, Value) {
    var msg = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i;
        break;
      }
    }
    return msg;
  }

  //----------------------------------------------

  useEffect(() => {
    let arr = [];
    axios
      .get(BaseUrl + "/Data/GetColumnsByTable/" + props.tableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        res.data &&
          res.data.columns.map((item) => {
            if (
              FindIndexInObj(props.allTableHead, "columnId", item.columnId) >= 0
            ) {
              arr.push({ ...item, showColumn: false });
            }
          });
      })
      .catch(() => {})
      .finally(() => {
        setTablesList(arr);
      });
  }, [open]);
  //----------------------------------------------------------------------------
  const [columnsIdsList, setColumnsIdsList] = useState([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(null);
  const handleSelectAllChange = (e) => {
    let isChecked = e.target.checked;

    setSelectAllCheckbox(isChecked);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  //----------------------------------------------------
  useEffect(() => {
    // console.log(props.toShowColumnArray);
    // const allExist = columnsIdsList.every((item) =>
    //   [...props.toShowColumnArray].includes(item)
    // );
    // if (allExist && selectAllCheckbox === false) {
    //   setSelectAllCheckbox(true);
    // } else if (!allExist && selectAllCheckbox === true) {
    //   setSelectAllCheckbox(false);
    // }
  }, [props.toShowColumnArray]);
  //---------------------------------------
  useEffect(() => {
    let arr = [];
    for (let index = 0; index < tablesList.length; index++) {
      const element = tablesList[index];

      arr.push(element.columnId);
    }
    setColumnsIdsList(arr);
  }, [tablesList]);
  //-------------------------------
  useEffect(() => {
    if (selectAllCheckbox === true) {
      const mergedArray = [...props.toShowColumnArray, ...columnsIdsList];
      const uniqueArray = [...new Set(mergedArray)];
      props.setToShowColumnArray(uniqueArray);
    } else if (selectAllCheckbox === false) {
      props.setToShowColumnArray(
        [...props.toShowColumnArray].filter(
          (item) => !columnsIdsList.includes(item)
        )
      );
    }
  }, [selectAllCheckbox]);

  //----------------------------------------------------------------------------
  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=""
        className="w-100 p-0"
      >
        <OutputTableXls
          tableName={props.tableName}
          tableId={props.tableId}
          selectAllCheckbox={selectAllCheckbox}
          handleSelectAllChange={handleSelectAllChange}
          onClick={stopPropagation}
          open={open}
        />
      </div>
      <Collapse in={open} className="collapse-content">
        <div
          className="ms-3 border-start"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          {tablesList &&
            tablesList.map((item, index) => (
              <DataType
                selectAllCheckbox={selectAllCheckbox}
                setSelectAllCheckbox={setSelectAllCheckbox}
                key={index}
                setRelationshipJson={props.setRelationshipJson}
                relationShipJson={props.relationShipJson}
                tableId={props.tableId}
                tableName={props.tableName}
                columnId={item.columnId}
                columnName={item.columnName}
                setShowR={props.setShowR}
                setTitle={props.setTitle}
                index={index}
                setChangeColumns={props.setChangeColumns}
                EditReportIdHead={props.EditReportIdHead}
                setEditReportIdHead={props.setEditReportIdHead}
                allTableHead={props.allTableHead}
                setAllTableHead={props.setAllTableHead}
                open={open}
                setObj={props.setObj}
                obj={props.obj}
                setToShowColumnArray={props.setToShowColumnArray}
                toShowColumnArray={props.toShowColumnArray}
                reportSelectedColumns={props.reportSelectedColumns}
              />
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default OutputXls;
