import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import BarGraph from "../../AUD_DashboardElements/Charts/barGraph";
import PieGraph from "../../AUD_DashboardElements/Charts/pieGraph";
import QueryModal from "../../AUD_DashboardElements/QueryModal";
import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { format } from "date-fns";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Graph = ({
  title,
  height,
  editMode,
  viewQueryResult,
  queryResult,
  index,
  reloadDashboard,
  graphType,
  titles,
  queryStructures,
  widgetType,
  seriesTitles,
  stacked = false,
  max = 100,
  data,
  pre = "",
  withTabs,
  fullWidthContainer,
  maxCategoryLength,
  showGraph = true,
  percentage = false,
  scroll,
  selectedFilters,
}) => {
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [query, setQuery] = useState();
  const [maxValue, setMaxValue] = useState(max);
  const [groupByColumn, setGroupByColumn] = useState();

  const [defaultQueryStructure, setDefaultQueryStructure] = useState(
    queryStructures[0]
  );
  const [colors, setColors] = useState(
    stacked
      ? [
          "#93070A",
          "#DB161B",
          "#FEBCB6",
          "#718094",
          "#1B224C",
          "#166007",
          "#7FD349",
        ]
      : ["#2463EB"]
  );
  const [allSeries, setAllSeries] = useState({
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
  });
  const [allCategories, setAllCategories] = useState({
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
  });
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [tabIndex, setTabIndex] = useState(index[0]);
  const [type, setType] = useState(graphType[0]);
  //----------------------------------------------------
  const [tabs, setTabs] = useState([
    {
      index: index[0],
      title: titles[0],
      width: "23.5%",
      active: true,
      categories: allCategories.tab1,
      series: allSeries.tab1,
      hasError: false,
      type: graphType[0],
    },
    {
      index: index[1],
      title: titles[1],
      width: "23.5%",
      active: false,
      categories: allCategories.tab2,
      series: allSeries.tab2,
      hasError: false,
      type: graphType[1],
    },
    {
      index: index[2],
      title: titles[2],
      width: "23.5%",
      active: false,
      categories: allCategories.tab3,
      series: allSeries.tab3,
      hasError: false,
      type: graphType[2],
    },
    {
      index: index[3],
      title: titles[3],
      width: "23.5%",
      active: false,
      categories: allCategories.tab4,
      series: allSeries.tab4,
      hasError: false,
      type: graphType[3],
    },
  ]);
  //---------------------------------------------------
  const getObjByIndex = (array, i) => {
    const item = array.find((element) => element.title === i);
    return item ? item : "noObject";
  };
  //---------------------------------------------------
  function setActiveTab(arr, Value) {
    if (tabIndex !== getObjByIndex(arr, Value).index) {
      const array = [...arr];
      for (var i = 0; i < array.length; i++) {
        array[i]["active"] = false;
      }
      let a;
      for (var i = 0; i < array.length; i++) {
        if (array[i].title === Value) {
          array[i].active = true;
          setCategories(array[i].categories);
          setSeries(array[i].series);
          setTabIndex(index[i]);
          a = i;
          break;
        }
      }

      setDefaultQueryStructure(queryStructures[a]);
      setTabs(array);
      setType(getObjByIndex(arr, Value).type);
    }
  }
  //----------------------------------------------------
  const handleSave = () => {
    let Query = {
      queryId: tabIndex,
      queryType: "report",
      widgetType: widgetType[index.indexOf(tabIndex)],
      queryName: query.queryName,
      queryText: query.query,
      reportId: query.reportId,
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
      platformName: "AUD",
    };
    // console.log(Query);
    if (query.queryName && query.query && query.reportId) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewQueryResult([tabIndex], selectedFilters);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {});
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  //----------------------------------------------
  useEffect(() => {
    // console.log(selectedFilters);

    viewQueryResult([21, 22, 23, 24], selectedFilters);
  }, [selectedFilters]);

  ///**********************
  const isArrayofJSONObjects = (variable) => {
    if (!Array.isArray(variable)) {
      return false;
    }

    return variable.every(
      (item) =>
        typeof item === "object" && item !== null && !Array.isArray(item)
    );
  };
  //----------------------------------------------

  useEffect(() => {
    // console.log(data);

    for (let i = 0; i < data.length; i++) {
      const queryResult = data[i];

      if (queryResult && queryResult.queryId !== tabIndex) {
        continue;
      }

      if (
        queryResult &&
        queryResult.queryId === tabIndex &&
        queryResult.content.length
      ) {
        let result = queryResult.content;
        if (result !== "no" && isArrayofJSONObjects(result)) {
          let cat = [];
          for (let j = 0; j < result.length; j++) {
            const element = result[j];

            element["xAxis"] &&
              // element["xAxis"] != "0" &&
              cat.push(
                widgetType[index.indexOf(tabIndex)] !== "LineDateGraph"
                  ? `${pre}${element["xAxis"]}`
                  : format(new Date(element["xAxis"]), "MMMM dd") //MMMM dd, yyyy
              );
          }

          let arrayOfSeries = [];
          for (let j = 0; j < seriesTitles.length; j++) {
            const eachTitle = seriesTitles[j];
            let ser = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];

              if (percentage) {
                element["xAxis"] &&
                  // element["xAxis"] != "0" &&
                  element &&
                  element.zAxis &&
                  ser.push(element["zAxis"][j]);
              } else {
                element["xAxis"] &&
                  // element["xAxis"] != "0" &&
                  element &&
                  element.yAxis &&
                  ser.push(element["yAxis"][j]);
              }
            }
            arrayOfSeries.push({
              name: eachTitle,
              data: ser,
            });
          }

          setSeries(arrayOfSeries);
          setCategories(cat);
        }

        break;
      }
    }
    // console.log("New Data");
  }, [data]);

  //---------------------------------------------------
  useEffect(() => {
    // console.log(series);
    // console.log(categories);
  }, [series]);

  useEffect(() => {
    viewQueryResult([query && query.queryId], selectedFilters);
    // console.log("Updated the query");
  }, [query]);

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedGraphQuery("AUD");

        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.queryId !== tabIndex) {
            continue;
          }
          if (element.queryId === tabIndex) {
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
              queryId: element.queryId,
            });
            setGroupByColumn(element.groupByColumn && element.groupByColumn);
            break;
          }
        }
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();
    // console.log("select tab");
  }, [tabIndex]);
  //---------------------------------------------------

  return (
    <>
      {showGraph && (
        <BarGraph
          groupByColumn={groupByColumn}
          selectedFilters={selectedFilters}
          maxCategoryLength={maxCategoryLength}
          withQuery={editMode}
          setAskQueryModal={setAskQueryModal}
          height={height}
          title={title}
          Gtype={type ?? graphType}
          tabs={withTabs && tabs}
          showtoolbar={false}
          colors={colors}
          series={series}
          categories={categories}
          handleSave={handleSave}
          setActiveTab={setActiveTab}
          stacked={stacked}
          max={maxValue}
          pre={pre}
          queryStructures={queryStructures}
          fullWidthContainer={fullWidthContainer}
          percentage={percentage}
          scroll={scroll}
        />
      )}

      {askQueryModal ? (
        <QueryModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          handleSave={handleSave}
          setCategories={setCategories}
          setSeries={setSeries}
          categories={categories}
          series={series}
          defaultQueryStructure={defaultQueryStructure}
        />
      ) : null}
    </>
  );
};

export default Graph;
