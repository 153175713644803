import React, { useEffect, useState, createContext } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/theme.css";
import "./assets/css/index.css";
import "./assets/edulytics-icons/css/edulytics-icons.min.css";
import "../src/assets/gilroy-font/css/font.css";
import Layout from "./components/layout/Layout";
import { ToastContainer } from "react-toastify";

import { ToastProvider } from "./components/Molecules/Custom/CustomToaster/Toast";
//-------

const store = createStore(rootReducer);

document.title = "EduLytics";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer />

      <Layout />
    </React.StrictMode>
  </Provider>
  // document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     {/* <ToastProvider> */}
//       <Layout />
//     {/* </ToastProvider> */}
//   </React.StrictMode>
// );

reportWebVitals();
