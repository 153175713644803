import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
// import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Paginator.module.scss";

const CustomPaginator = ({
  totalItems,
  setTablePage,
  setTablePageSize,
  perPage,
  page,
  queryId,
  defaultPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [itemsPerPage, setItemsPerPage] = useState(perPage); // Default rows per page

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageButtons = 5; // Max number of visible page buttons
  //-------------------------------------------

  // Handle rows per page change
  const handleRowsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page
  };
  //-------------------------------------------

  // Handle page change
  const handlePageChange = (pagee) => {
    setCurrentPage(pagee);
  };
  //-------------------------------------------

  useEffect(() => {
    setTablePage(currentPage);
  }, [currentPage]);

  //-------------------------------------------

  useEffect(() => {
    setTablePageSize(itemsPerPage);
    setTablePage(1);
  }, [itemsPerPage]);
  //-------------------------------------------

  useEffect(() => {
    setItemsPerPage(defaultPerPage);
    setCurrentPage(1);
  }, [queryId]);
  //-------------------------------------------

  const renderPaginationItems = () => {
    let paginationItems = [];

    if (totalPages <= maxPageButtons) {
      // If total pages are less than max visible buttons, show all
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Show first, last, current page, and ellipsis
      paginationItems.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );

      if (currentPage > 3) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Pages around the current page
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalItems);

  return (
    <div
      className={`${styles.paginationContainer} ${
        totalItems ? styles.show : styles.hide
      } row`}
    >
      <div className={`${styles.rowsPerPage} `}>
        <span htmlFor="rowsPerPage">Show: </span>
        <select
          id="rowsPerPage"
          value={itemsPerPage}
          onChange={handleRowsPerPageChange}
          className="showPerPage"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className={`${styles.pagination} `}>
        {/* Pagination Component */}
        <Pagination className="custom-pagination">
          <Pagination.Prev
            className="Prev"
            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          />
          {renderPaginationItems()}
          <Pagination.Next
            className="Next"
            onClick={() =>
              currentPage < totalPages && handlePageChange(currentPage + 1)
            }
          />
        </Pagination>
      </div>
    </div>
  );
};

export default CustomPaginator;
