import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GetBaseUrl from "../../functions/SetBaseUrl";
import {
  GetAPIList,
  GetAPIById,
  GetReportColumnsById,
} from "../../functions/GetAPIList";
import { extractStringsBetweenBrackets } from "../../functions/FunctionsStore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
function Blackboard(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext

  const [reportsList, setReportsList] = useState([]);

  const handleChange = (event) => {
    let obj = { ...props.query };
    obj[event.target.name] = event.target.value;
    props.setQuery(obj);
  };

  const handleClose = () => props.setAskQueryModal(false);

  useEffect(() => {
    // console.log(props.selectedCard);
  }, [props.selectedCard]);

  //---------------------------------------------------  (1)
  useEffect(() => {
    const getReportsList = async () => {
      try {
        const data = await GetAPIList("reports", "");
        setReportsList(data);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    getReportsList();
  }, []);

  const runQuery = () => {
    if (handleSave()) {
      handleClose();
    }
  };

  //----------------------------------------------------
  const handleSave = () => {
    // console.log(props.query);

    let Query = {
      queryId: props.queryId,
      queryType: "statistics",
      queryName: props.query.queryName,
      queryText: props.query.query,

      reportId: parseFloat(props.query.reportId),
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
      DateColumnId: "00000000-0000-0000-0000-000000000000",
    };
    // console.log(Query);

    let updatedArray = [...props.statistics];

    if (props.query.queryName !== "" && props.query.reportId !== 0) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // console.log(res.data);
          props.viewQueryResult(props.queryId);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  //---------------------------------------------------

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>Edit Statistics</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Title</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="Enter Title"
                name="queryName"
                value={props.query && props.query.queryName}
                onChange={handleChange}
              />
            </div>

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Report </label>
              <Form.Select
                className="input-bordered field-input mb-4 "
                aria-label="Default select example "
                onChange={handleChange}
                name="reportId"
                value={props.query && props.query.reportId}
              >
                <option value={0}>Select Report</option>
                {reportsList &&
                  reportsList.map((report) => {
                    return (
                      <option key={report.Id} value={report.Id}>
                        {report.Name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>

            {props.queryId === 204 ? (
              <div className={`${PIS["container-100-100-100"]}`}>
                <label className="fs-7 fw-semibold"> Date column </label>
                <Form.Select
                  className="input-bordered field-input mb-4 "
                  aria-label="Default select example "
                  onChange={props.handleChangeDateColumn}
                  name="selectedColumn"
                  value={props.selectedDateColumn && props.selectedDateColumn}
                >
                  <option>Select Column</option>
                  {props.columns &&
                    props.columns.map((column) => {
                      return (
                        <option key={column.columnId} value={column.columnId}>
                          {column.columnName}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            ) : null}

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Query</label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter your query here"
                className="input-bordered p-3 py-2   mb-4"
                name="query"
                value={props.query && props.query.query}
                onChange={handleChange}
              />
            </div>

            <Button
              className="main-button w-100 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => runQuery()}
            >
              Run Query
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Blackboard;
