import {
  border,
  borderLeft,
  borderRadius,
  height,
  maxHeight,
} from "@mui/system";

export const comprehensiveStylesBlue = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    margin: "0 auto",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#EEF7FF",
    borderColor: "#EEF7FF",

    // minHeight: 38,
    cursor: "pointer",
    outline: state.isFocused ? "0.1px solid #EEF7FF" : "none",
    height: state.isFocused ? 48 : 48,
    borderRadius: "5px",

    "&:hover": {
      borderColor: "#EEF7FF",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 10,
  }),
  indicatorSeparator: (provided) => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#888",
    "&:hover": {
      color: "#FFF",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#2684FF"
      : state.isFocused
      ? "#f0f0f0"
      : "#fff",
    color: state.isSelected ? "#fff" : "#333",
    "&:hover": {
      backgroundColor: state.isFocused ? "#e0e0e0" : null,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
};

export const comprehensiveStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    margin: "0 auto",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#f9f9f9",
    borderColor: "#f9f9f9",
    // minHeight: 38,
    cursor: "pointer",
    outline: state.isFocused ? "0.1px solid #F9F9F9" : "none",
    height: state.isFocused ? 48 : 48,
    borderRadius: "0px",

    "&:hover": {
      borderColor: "#f9f9f9",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 10,
  }),
  indicatorSeparator: (provided) => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#888",
    "&:hover": {
      color: "#666",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#2684FF"
      : state.isFocused
      ? "#f0f0f0"
      : "#fff",
    color: state.isSelected ? "#fff" : "#333",
    "&:hover": {
      backgroundColor: state.isFocused ? "#e0e0e0" : null,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
};
