import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../functions/SetBaseUrl";
import GraphQuery from "./AUD_DashboardElements/Graphs/GraphQuery";
import DashboardHeader from "./AUD_DashboardElements/DashboardHeader";
import DashboardStatics from "./AUD_DashboardElements/DashboardStatics";
import "./AUD_DashboardElements/Dashboard.css";
import { Scholarship } from "./AUD_DashboardElements/Charts/Scholarship";
import { Programs } from "./AUD_DashboardElements/Charts/Programs";
import { IndexContext } from "../components/layout/Layout";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Dashboard = () => {
  const IndexProvider = useContext(IndexContext);
  const [editMode, setEditMode] = useState(false);
  const [comparisonMode, setComparisonMode] = useState(false);
  const [graphs, setGraphs] = useState([]);
  const [multiGraphs, setMultiGraphs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedComparisonFilters, setSelectedComparisonFilters] = useState(
    []
  );
  const [reloadDashboard, setReloadDashboard] = useState(0);
  //-------------------------------------------------------
  let queryStructures2_1 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures2_2 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures2_3 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures3_1 = `Select
  Count
  Group By - visitdate`;

  let queryStructures3_2 = `Select
  Count
  Group By - Created_at`;

  let queryStructures3_3 = `Select
  Count
  Group By - Created_at`;

  let queryStructures3_4 = `Select
  Count
  Group By - Created_at`;
  //-------------------------------------------------------
  const [queryStructures, setQueryStructures] = useState([
    {
      index: [21, 22, 23, 24],
      titles: ["Country", "School", "College", "Nationality"],
      widgetType: ["LineGraph", "LineGraph", "LineGraph", "LineGraph"],
      graphType: ["pie", "bar", "bar", "flag"],
      seriesTitles: ["Active"],
      max: 10,
      height: 417,
      title: "",
      stacked: false,
      pre: "",
      options: [
        queryStructures3_1,
        queryStructures3_2,
        queryStructures3_3,
        queryStructures3_4,
      ],
    },
    {
      index: [41, 42, 43],
      titles: [],
      widgetType: [
        "LineStackPercentageGraph",
        "LineStackGraph",
        "LineStackGraph",
      ],
      graphType: ["bar", "bar", "bar"],
      seriesTitles: [
        "Leads",
        "Applicants",
        "Deposit",
        "Registered w/o Credit",
        "Registered w/o Credit",
      ],
      max: 100,
      height: 460,
      title: "",
      stacked: true,
      pre: "",
      options: [queryStructures2_1, queryStructures2_2, queryStructures2_3],
    },
  ]);
  //---------------------------------------------------
  const viewQueryResult = (charts = [], Filters = []) => {
    // console.log(charts);

    // setGraphs([]);
    if (charts.length) {
      getGraphResult(charts, Filters);
    } else {
      setGraphs([]);
      getGraphResult([21, 22, 23, 24], Filters);
      getGraphResult([41, 42, 43], Filters);
    }
  };
  //---------------------------------------------------getGraphResult
  const getGraphResult = async (graphIndex, Filters = [], multi = false) => {
    let fullArray = [];
    for (let i = 0; i < graphIndex.length; i++) {
      const element = graphIndex[i];
      let obj = {
        queryId: element,
        filterData: Filters,
      };
      // console.log(obj);

      if (Filters.length) {
        try {
          const response = await axios.post(
            BaseUrl + "/Report/GetDashboardReportQueryData",
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
            }
          );

          fullArray.push({ queryId: element, content: response.data });
        } catch (error) {
          fullArray.push({
            queryId: element,
            content: [],
          });
        }
      }
    }
    if (multi) {
      setMultiGraphs(fullArray);
    } else {
      setGraphs(fullArray);
    }
  };

  //---------------------
  useEffect(() => {
    !comparisonMode && setSelectedComparisonFilters([]);
  }, [comparisonMode]);

  //----------------------------------------------getGraphResult

  return (
    <div className="rounded-0 Dashboard-container min-vh-85">
      <DashboardHeader
        setEditMode={setEditMode}
        editMode={editMode}
        setComparisonMode={setComparisonMode}
        comparisonMode={comparisonMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        viewQueryResult={viewQueryResult}
        setReloadDashboard={setReloadDashboard}
        setSelectedComparisonFilters={setSelectedComparisonFilters}
        selectedComparisonFilters={selectedComparisonFilters}
      />
      <DashboardStatics
        setEditMode={setEditMode}
        editMode={editMode}
        comparisonMode={comparisonMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        reloadDashboard={reloadDashboard}
        setReloadDashboard={setReloadDashboard}
        viewQueryResult={viewQueryResult}
        selectedComparisonFilters={selectedComparisonFilters}
      />

      <div className="charts-container row ">
        <GraphQuery
          withTabs
          selectedFilters={selectedFilters}
          key={queryStructures[0].index}
          height={queryStructures[0].height}
          title={queryStructures[0].title}
          widgetType={queryStructures[0].widgetType}
          data={graphs}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          index={queryStructures[0].index}
          titles={queryStructures[0].titles}
          reloadDashboard={reloadDashboard}
          graphType={queryStructures[0].graphType}
          queryStructures={queryStructures[0].options}
          seriesTitles={queryStructures[0].seriesTitles}
          max={queryStructures[0].max}
          stacked={queryStructures[0].stacked}
          pre={queryStructures[0].pre}
          maxCategoryLength={10}
          scroll
        />
        <Scholarship
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          tableTitle={""}
          widgetType=""
          queryStructures={[]}
          withQuery={editMode}
        />

        <Programs
          selectedFilters={selectedFilters}
          viewQueryResult={viewQueryResult}
          queryResult={graphs}
          setEditMode={setEditMode}
          editMode={editMode}
          reloadDashboard={reloadDashboard}
          QS={queryStructures[1]}
        />
      </div>
    </div>
  );
};

export default Dashboard;
