import React, { useEffect, useState, useRef, useContext } from "react";

import "./customTable.css";

import styles from "./customTable.module.scss";
import { IndexContext } from "../../components/layout/Layout";

import TableColumnsIcon from "../../assets/svg/Dashboard/AUD/tableColumnsIcon.svg";
import TableDottesIcon from "../../assets/svg/Dashboard/AUD/tableDottesIcon.svg";
import TableFilterIcon from "../../assets/svg/Dashboard/AUD/tableFilterIcon.svg";
import arrowUp from "../../assets/svg/arrowUp.svg";
import arrowDown from "../../assets/svg/arrowDown.svg";
import inactive_arrow from "../../assets/svg/inactive_arrow.svg";
import ToggleColumnsVisibility from "../Atoms/ToggleColumnsVisibility";
import ToggleOptions from "../Atoms/ToggleOptions";
import StatusBtn from "../../components/Atoms/StatusBtn";
import StatusBtnWarning from "../../assets/svg/Dashboard/AUD/StatusBtnWarning.svg";
import StatusBtnSuccess from "../../assets/svg/Dashboard/AUD/StatusBtnSuccess.svg";
import { handleBreakpoints } from "@mui/system";

import InputText from "../Atoms/InputText";
import { Rings } from "react-loader-spinner";

export default function TableCustomized(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  const [activeColumn, setActiveColumn] = useState(props.selectedColumn);
  const [up, setUp] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [sortBy, setSortBy] = useState(props.selectedColumn);
  const [sortType, setSortType] = useState(props.sortDirection);

  const [searchNormalResults, setSearchNormalResults] = useState(props.data);
  const [searchNormalExportResults, setSearchNormalExportResults] = useState(
    props.ExportList
  );

  const [tableColumns, setTableColumns] = useState(props.tableHeader);

  //-------------------------------------------------------

  const emptyRows = 0;

  useEffect(() => {
    setSearchNormalResults(props.data);
  }, [props.data]);

  useEffect(() => {
    setSearchNormalExportResults(props.ExportList);
  }, [props.ExportList]);

  useEffect(() => {
    let arr = [...tableColumns];
    props.setTableHeader(arr);
  }, [tableColumns]);

  useEffect(() => {
    props.setSortDirection(sortType);
  }, [sortType]);

  useEffect(() => {
    props.setSelectedColumn(activeColumn);
  }, [activeColumn]);

  useEffect(() => {
    if (sortType === "ASC") {
      setUp(true);
    } else {
      setUp(false);
    }
  }, [sortType]);
  //-------------------------------------------------------------

  return (
    <div className={`  `} style={{ position: "relative" }}>
      {props.headerBtns && (
        <div className={`${PIS["row"]}`}>
          <div
            className={` ${
              props.smallTable ? "smalltableTitleText" : "tableTitleText"
            } `}
          >
            {props.normalTableName}
          </div>
          <div
            className={`tableOptions 
            `}
          >
            <ToggleOptions
              container_class="Options"
              setExportOption={props.setExportOption}
            />
            {props.columnsFilters && (
              <img
                src={TableFilterIcon}
                onClick={() => setShowFilters(!showFilters)}
              />
            )}
            {props.columnsVisibility && (
              <ToggleColumnsVisibility
                icon={TableColumnsIcon}
                container_class="Options"
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
              />
            )}
          </div>
        </div>
      )}

      <div className="table-wrapper overflow-x-auto px-1 w-100 row mt-3">
        <table
          className=".custom_table table-bordered1 mt-1"
          aria-label="custom pagination table"
        >
          <thead className="custom_thead">
            <tr className="custom_tr fs-7 fw-semibold">
              {props.tableHeader.map(
                (item, index) =>
                  item.visible && (
                    <th
                      key={item.name}
                      scope="col"
                      className="custom_th fw-semibold "
                      style={{ position: "relative", width: item.width }}
                    >
                      {item.name}
                      <img
                        className="pointer"
                        style={{
                          position: "absolute",
                          right: "5%",
                          top: "35%",
                        }}
                        src={
                          activeColumn === item.originalName && up
                            ? arrowUp
                            : activeColumn === item.originalName && !up
                            ? arrowDown
                            : inactive_arrow
                        }
                        alt="By"
                        onClick={() => {
                          activeColumn === item.originalName &&
                            setSortType(sortType === "ASC" ? "DESC" : "ASC");
                          setSortBy(item.originalName);
                          setActiveColumn(item.originalName);
                        }}
                      />
                    </th>
                  )
              )}
            </tr>
          </thead>

          <tbody
            className="custom_tbody fs-8"
            style={{ maxHeight: props.smallTable ? "220px" : "" }}
          >
            {showFilters ? (
              <tr
                className="custom_tr pointer"
                style={{ height: 34 * emptyRows }}
              >
                {props.tableHeader.map(
                  (i) =>
                    i.visible && (
                      <td
                        key={i.originalName}
                        className="custom_td filterCell"
                        style={{
                          // border: "1px solid black",
                          width: i.width,
                          padding: "0%",
                        }}
                      >
                        <InputText
                          placeholder={"Search in " + i.name}
                          name={i.originalName}
                          setSelectedFilters={props.setSelectedFilters}
                          selectedFilters={props.selectedFilters}
                          internalFilter={props.internalFilter}
                          setInternalFilter={props.setInternalFilter}
                          queryId={props.queryId}
                        />
                      </td>
                    )
                )}
              </tr>
            ) : null}

            {props.loading ? (
              <div className="d-flex justify-content-center flex-column align-items-center">
                <Rings color="#05BC86" height={350} width={250} />{" "}
              </div>
            ) : searchNormalResults && searchNormalResults.length ? (
              searchNormalResults.map((item, index) => (
                <tr
                  key={index}
                  className="custom_tr pointer"
                  style={{ height: 34 * emptyRows }}
                  onClick={() => {
                    props.setSelectedUser(item);
                  }}
                >
                  {props.tableHeader.map(
                    (i) =>
                      i.visible && (
                        <td
                          className="custom_td"
                          key={i.originalName}
                          onClick={() => props.setViewMode(true)}
                          style={{ width: i.width }}
                        >
                          {item &&
                            item.map((col) =>
                              col.columnName &&
                              col.columnName === i.originalName &&
                              props.lastColumn === i.originalName ? (
                                <StatusBtn
                                  warningIcon={StatusBtnWarning}
                                  successIcon={StatusBtnSuccess}
                                  title={col.rowData}
                                  status={
                                    col.rowData === "Enrolled"
                                      ? "Success"
                                      : col.rowData === "Applicant Pending" ||
                                        col.rowData ===
                                          "Applicant Pending - Reject"
                                      ? "Warning"
                                      : col.rowData ===
                                          "Reject - Applicant Pending" ||
                                        col.rowData === "Reject" ||
                                        col.rowData === "Cancel"
                                      ? "Error"
                                      : col.rowData === "Postpone" ||
                                        col.rowData === "Application Closed"
                                      ? "Info"
                                      : ""
                                  }
                                />
                              ) : col.columnName === i.originalName &&
                                props.lastColumn !== i.originalName ? (
                                col.rowData && col.rowData.length > 28 ? (
                                  col.rowData.substring(0, 28) + ".."
                                ) : (
                                  col.rowData
                                )
                              ) : (
                                ""
                              )
                            )}
                        </td>
                      )
                  )}
                </tr>
              ))
            ) : (
              searchNormalResults &&
              !searchNormalResults.length && (
                <tr className="custom_tr pointer">
                  <td
                    align="center"
                    className="custom_td no_result "
                    colSpan={props.tableHeader.length + 1}
                    // style={{ "text-align": "center" }}
                  >
                    <h5> No Result Found </h5>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <table
          id="Applicants_Table"
          className=" hide"
          aria-label="custom pagination table"
        >
          <thead className="custom_thead">
            <tr className="custom_tr fs-7 fw-semibold">
              {props.tableHeader.map(
                (item, index) =>
                  item.visible && (
                    <th
                      key={item.name}
                      scope="col"
                      className="custom_th fw-semibold "
                      style={{ position: "relative", width: item.width }}
                    >
                      {item.name}
                    </th>
                  )
              )}
            </tr>
          </thead>

          <tbody
            className="custom_tbody fs-8"
            style={{ maxHeight: props.smallTable ? "220px" : "auto" }}
          >
            {searchNormalExportResults &&
              searchNormalExportResults.length &&
              searchNormalExportResults.map((item, index) => (
                <tr
                  key={index}
                  className="custom_tr pointer"
                  style={{ height: 34 * emptyRows }}
                  onClick={() => {
                    props.setSelectedUser(item);
                  }}
                >
                  {props.tableHeader.map(
                    (i) =>
                      i.visible && (
                        <td
                          className="custom_td"
                          key={i.originalName}
                          onClick={() => props.setViewMode(true)}
                          style={{ width: i.width }}
                        >
                          {item &&
                            item.map((col) =>
                              col.columnName &&
                              col.columnName === i.originalName &&
                              props.lastColumn === i.originalName ? (
                                <StatusBtn
                                  warningIcon={StatusBtnWarning}
                                  successIcon={StatusBtnSuccess}
                                  title={col.rowData}
                                  status={
                                    col.rowData === "Enrolled"
                                      ? "Success"
                                      : col.rowData === "Applicant Pending" ||
                                        col.rowData ===
                                          "Applicant Pending - Reject"
                                      ? "Warning"
                                      : col.rowData ===
                                          "Reject - Applicant Pending" ||
                                        col.rowData === "Reject" ||
                                        col.rowData === "Cancel"
                                      ? "Error"
                                      : col.rowData === "Postpone" ||
                                        col.rowData === "Application Closed"
                                      ? "Info"
                                      : ""
                                  }
                                />
                              ) : col.columnName === i.originalName &&
                                props.lastColumn !== i.originalName ? (
                                col.rowData && col.rowData.length > 28 ? (
                                  col.rowData.substring(0, 28) + ".."
                                ) : (
                                  col.rowData
                                )
                              ) : (
                                ""
                              )
                            )}
                        </td>
                      )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
