const setBase = () => {
  let base = window.location.origin;
  var baseUrl = "";
  var loginBaseUrl = "";
  var GPTBaseUrl = "";
  var serverType = "";

  if (base === "http://localhost:3000") {
    // baseUrl= 'https://eduapis.ctsdemo.ae'
    baseUrl = "https://testingapis.edulytics.ae";
    baseUrl = "https://testingapis.edulytics.ae/gateway";
    loginBaseUrl = "https://testingapis.edulytics.ae";
    GPTBaseUrl = "https://aiducatorstagingapis.ctsdemo.ae";

    serverType = "LocalHost";
  } else if (base === "https://edulyticsup.ctsdemo.ae") {
    //baseUrl= 'https://eduapis.ctsdemo.ae'
    baseUrl = "https://edapis.ctsdemo.ae";
    serverType = "Upgrade";
    GPTBaseUrl = "https://aiducatorstagingapis.ctsdemo.ae";
  } else {
    baseUrl = "https://testingapis.edulytics.ae";
    baseUrl = "https://testingapis.edulytics.ae/gateway";
    loginBaseUrl = "https://testingapis.edulytics.ae";
    serverType = "Testing";
    GPTBaseUrl = "https://aiducatorstagingapis.ctsdemo.ae";
  }

  return [baseUrl, serverType, loginBaseUrl, GPTBaseUrl];
};

export default setBase;
