import React from "react";

import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Diagram from "../pages/Diagram";
import DataInputs from "../pages/DataInputs";
import Relationships from "../pages/Relationships";
import Query from "../pages/AddQuery";
import Outputs from "../pages/Outputs";
import AI_TutorDashboard from "../pages/Dashboard";
import AUD_Dashboard from "../pages/AUD_Dashboard";
import UploadDocuments from "../pages/UploadDocuments";
import Tables from "../pages/Tables";
import DataFields from "../pages/DataFields";
import CreateRelation from "../pages/CreateRelation";
import SavedQuery from "../pages/SavedQuery";
import SavedOutput from "../pages/SavedOutput";
import Setting from "../pages/Settings";
import Login from "../pages/Login";
import API from "../pages/API";
import Users from "../pages/Users";
import UsersPage from "../pages/Users/UsersPage";
import Report from "../pages/Report";
import Platfrom from "../pages/Platfrom";

import Office360 from "../pages/Platforms/Office360";
import Canvas from "../pages/Platforms/Canvas";
import AUD from "../pages/Platforms/AUD";
import BlackBoard from "../pages/Platforms/BlackBoard";
import ChooseDirectory from "../pages/ChooseDirectory";
import ConnectBlackBoard from "../pages/Platforms/Connect/ConnectBlackBoard";
import ConnectOffice360 from "../pages/Platforms/Connect/ConnectOffice365";
import ConnectOffice365 from "../pages/Platforms/Connect/ConnectOffice365";
import ConnectCanvas from "../pages/Platforms/Connect/ConnectCanvas";
import ConnectAUD from "../pages/Platforms/Connect/ConnectAUD";
import ConnectASI from "../pages/Platforms/Connect/ConnectASI";
import DirectoryList from "../pages/DirectoryList";
import EditReport from "../pages/EditReport";
import ViewReport from "../pages/ViewReport";
// import Try from "../pages/try";
import Jobs from "../pages/Jobs";
import Filter from "../pages/Filtering";
import Filtering from "../pages/Filtering";
import FilterColumn from "../pages/Filtering";
import ReportOption from "../pages/ReportOptions";
import examples from "../pages/FreeExample";
import GetBaseUrl from "../functions/SetBaseUrl";

import Memo from "../pages/TestFolder/Memoised";
import NoPermission from "../pages/NoPermission";

import NewCreateRelation from "../pages/NewCreateRelation";
import NewRelationships from "../pages/NewRelationships";
import Fields from "../pages/Fields";
import Conditonal from "../pages/Conditonal";
var role = localStorage.getItem(GetBaseUrl()[1] + "_Role");
var dashboardsPermissions = localStorage.getItem(
  GetBaseUrl()[1] + "_dashboardsPermissions"
);

const Routes = () => {
  const checkPermissions = (arrayOf, Permission, Page) => {
    let userPermissions = arrayOf.split(",");
    // console.log(userPermissions);

    if (userPermissions.indexOf(Permission) > -1) {
      return Page;
    } else {
      return NoPermission;
    }
  };

  return (
    <Switch>
      <Route path="/memo" exact component={Memo} />
      {/* <Route path="/try" exact component={Try} /> */}

      <Route path="/" exact component={Home} />
      <Route
        path="/AI_Tutor"
        exact
        component={checkPermissions(
          dashboardsPermissions,
          "AI_Tutor",
          AI_TutorDashboard
        )}
      />

      <Route
        path="/AUD-dashboard"
        exact
        component={checkPermissions(
          dashboardsPermissions,
          "AUD_Dashboard",
          AUD_Dashboard
        )}
      />
      {/* <Route path="/Diagram" exact component={Try} /> */}
      <Route
        path="/Users"
        exact
        component={role === "Admin" ? UsersPage : NoPermission}
      />

      <Route
        path="/ConnectDirectory/Office"
        exact
        component={role === "Admin" ? ConnectOffice360 : NoPermission}
      />
      <Route
        path="/ConnectDirectory/Canvas"
        exact
        component={role === "Admin" ? ConnectCanvas : NoPermission}
      />
      <Route
        path="/ConnectDirectory/AUD"
        exact
        component={role === "Admin" ? ConnectAUD : NoPermission}
      />
      <Route
        path="/ConnectDirectory/ASI"
        exact
        component={role === "Admin" ? ConnectASI : NoPermission}
      />
      <Route
        path="/ConnectDirectory/BlackBoard"
        exact
        component={role === "Admin" ? ConnectBlackBoard : NoPermission}
      />

      <Route
        path="/Office360/Platform/Tables"
        exact
        component={role === "Admin" ? Office360 : NoPermission}
      />
      <Route
        path="/Canvas/Platform/Tables"
        exact
        component={role === "Admin" ? Canvas : NoPermission}
      />
      <Route
        path="/AUD/Platform/Tables"
        exact
        component={role === "Admin" ? AUD : NoPermission}
      />
      <Route
        path="/BlackBoard/Platform/Tables"
        exact
        component={role === "Admin" ? BlackBoard : NoPermission}
      />

      <Route
        path="/DataInput"
        exact
        component={role === "Admin" ? DataInputs : NoPermission}
      />
      <Route
        path="/DataInput/Upload-Document"
        exact
        component={role === "Admin" ? UploadDocuments : NoPermission}
      />
      <Route
        path="/DataInput/Tables"
        exact
        component={role === "Admin" ? Tables : NoPermission}
      />
      <Route
        path="/DataInput/Data-Fields"
        exact
        component={role === "Admin" ? DataFields : NoPermission}
      />
      <Route
        path="/Relationships"
        exact
        component={role === "Admin" ? Relationships : NoPermission}
      />
      <Route
        path="/Relationships/Create"
        exact
        component={role === "Admin" ? CreateRelation : NoPermission}
      />

      <Route
        path="/Query"
        exact
        component={role === "Admin" ? Query : NoPermission}
      />
      <Route
        path="/Query/Saved-Query"
        exact
        component={role === "Admin" ? SavedQuery : NoPermission}
      />
      <Route
        path="/Reports"
        exact
        component={role === "Admin" ? Outputs : NoPermission}
      />
      <Route
        path="/Reports/Saved-Reports"
        exact
        component={role === "Admin" ? SavedOutput : NoPermission}
      />
      {/* <Route path="/Dashboard" exact component={Dashboard} /> */}
      <Route
        path="/Platform"
        exact
        component={role === "Admin" ? Platfrom : NoPermission}
      />
      <Route
        path="/Fields"
        exact
        component={role === "Admin" ? Fields : NoPermission}
      />

      <Route
        path="/DataInput/Directory"
        exact
        component={role === "Admin" ? ChooseDirectory : NoPermission}
      />
      <Route
        path="/Reports/Edit-Report"
        exact
        component={role === "Admin" ? EditReport : NoPermission}
      />
      <Route
        path="/Home/Reports/View-Report"
        exact
        component={role === "Admin" ? ViewReport : NoPermission}
      />
      <Route
        path="/Jobs"
        exact
        component={role === "Admin" ? Jobs : NoPermission}
      />
      <Route
        path="/Filter"
        exact
        component={role === "Admin" ? FilterColumn : NoPermission}
      />
      <Route
        path="/Reportoption"
        exact
        component={role === "Admin" ? ReportOption : NoPermission}
      />

      <Route
        path="/examples"
        exact
        component={role === "Admin" ? examples : NoPermission}
      />

      <Route
        path="/formatting"
        exact
        component={role === "Admin" ? Conditonal : NoPermission}
      />
      <Route
        path="/NewRelationships"
        exact
        component={role === "Admin" ? NewRelationships : NoPermission}
      />
      <Route
        path="/NewRelationships/Create"
        exact
        component={role === "Admin" ? NewCreateRelation : NoPermission}
      />
    </Switch>
  );
};

export default Routes;
