import React, { useEffect, useState, useContext } from "react";
import { useTable } from "react-table";
import styles from "./Scholarship.module.scss";
import Card1Icon from "../../../assets/svg/Dashboard/AUD/Card2.svg";
import Card2Icon from "../../../assets/svg/Dashboard/AUD/Card4.svg";
import Card3Icon from "../../../assets/svg/Dashboard/AUD/Card5.svg";
import StatisticIcon_4 from "../../../assets/svg/Dashboard/TopTableIcon4.svg";
import StatisticIcon_5 from "../../../assets/svg/Dashboard/TopTableIcon5.svg";
import PageArrowLeft from "../../../assets/svg/Dashboard/PageArrowLeft.svg";
import PageArrowRight from "../../../assets/svg/Dashboard/PageArrowRight.svg";
import EditQuery from "../../../assets/images/EditQuery.svg";
import QueryModal from "../QueryModal";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { GetAPISavedStaticsQuery } from "../../../functions/GetAPIList";

import GetBaseUrl from "../../../functions/SetBaseUrl";
// import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";
// import { GetAPISavedStaticsQuery } from "../../../functions/GetAPIList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Table from "../../../components/table/customTable";
import { width } from "@mui/system";
import { GetAPIFilterDataByColumnName } from "../../../functions/GetAPIList";

import QueryStaticsModal from "../QueryStaticsModal";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

export const Scholarship = ({
  // data,
  tableTitle,
  queryStructures,
  withQuery,
  widgetType,
  index,
  // viewQueryResult,
  queryResult,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [defaultQueryStructure, setDefaultQueryStructure] = useState(
    queryStructures[0]
  );
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [query, setQuery] = useState();
  const [tabIndex, setTabIndex] = useState(31);
  const [queryId, setQueryId] = useState();

  const [selectedScholarship, setSelectedScholarship] = useState("");
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [selectedScholarshipFilter, setSelectedScholarshipFilter] = useState(
    []
  );
  const [scholarships, setScholarships] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [activePeriod, setActivePeriod] = useState(0);

  const handleChangeScholarship = (event) => {
    event.target.id === "Scholarship" &&
      setSelectedScholarship(event.target.value);
    event.target.id === "Sponsor" && setSelectedSponsor(event.target.value);

    setSelectedScholarshipFilter(
      event.target.value != 0
        ? [
            {
              columnName: event.target.name,
              filterValue: event.target.value,
            },
          ]
        : []
    );
  };

  const headerArray = [
    {
      name: "Schools and Departments",
      originalName: "Student Name",
      visible: true,
      width: "60%",
    },

    {
      name: "Total Students",
      originalName: "Grade",
      visible: true,
      width: "40%",
    },
  ];
  const [leftSelected, setLeftSelected] = useState(0);

  const [statistics, setStatistics] = useState([
    {
      index: 31,
      reportId: 0,
      value: 0,
      percentage: 0,
      active: false,
      icon: Card1Icon,
      title: "All Applicants",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 32,
      reportId: 0,
      value: 0,
      percentage: 0,
      active: false,
      icon: Card2Icon,
      title: "NLS",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 33,
      reportId: 0,
      value: 0,
      percentage: 0,
      active: false,
      icon: Card3Icon,
      title: "Granted",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 34,
      reportId: 0,
      value: 0,
      percentage: 0,
      active: false,
      icon: Card3Icon,
      title: "Not Granted",
      defaultQueryStructure: `Select 
   Count`,
    },
  ]);
  //---------------------------------------------------
  const getValueByIndex = (array, i) => {
    const item = array.find((element) => element.queryId === i);
    return item ? item.content : "no";
  };

  //------------------------------
  const viewQueryResult = (index) => {
    let obj = {
      queryId: index,
      Period: activePeriod,
      filterData: [...selectedFilters],
      optionalFilterData: [...selectedScholarshipFilter],
    };
    // console.log(obj);

    let updatedArray = [...statistics];

    if (obj.filterData.length) {
      axios
        .post(BaseUrl + "/Report/GetDashboardReportQueryData", obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data && res.data.queryName) {
            for (var i = 0; i < updatedArray.length; i++) {
              if (updatedArray[i].index === index) {
                updatedArray[i].value = res.data.filterTotal;
                updatedArray[i].reportId = res.data.reportid ?? 0;
                updatedArray[i].percentage = res.data.percentageFilter ?? 0;

                updatedArray[i].title = res.data.queryName;
                break;
              }
            }
          }
        })
        .catch((res) => {
          for (var i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].index === index) {
              updatedArray[i].value = 0;
              updatedArray[i].percentage = 0;
              updatedArray[i].reportId = 0;
              updatedArray[i].title = "N/A";
              break;
            }
          }
        })
        .finally(() => {
          setStatistics(updatedArray);
        });
    }
  };

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery("AUD");
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === queryId) {
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          } else {
            setQuery({
              queryName: "",
              reportId: 0,
              query: "",
            });
          }
        }
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();
  }, [queryId]);

  //---------------------
  useEffect(() => {
    for (let i = 0; i < statistics.length; i++) {
      const element = statistics[i].index;
      [31, 32, 33].indexOf(element) > -1 && viewQueryResult(element);
    }
  }, [selectedFilters, selectedScholarshipFilter]);

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery();
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === tabIndex) {
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          }
        }
      } catch (error) {
        console.error("Error By Id1 :", error);
      }
    };
    SavedGraphQuery();
  }, []);
  //----------------------------------------------------

  const formatNumber = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return num;
    }
  };
  //----------------------------------------------
  const [loadingTable, setLoadingTable] = useState(0);

  //---------------------------------------------------------------------
  useEffect(() => {
    leftSelected && setLoadingTable(leftSelected);
  }, [leftSelected]);
  //---------------------------------------------------------------------
  useEffect(() => {
    const Fill_Filters = async (
      tableName,
      columnName,
      type,
      FirstFilterColumn
    ) => {
      try {
        const data = await GetAPIFilterDataByColumnName(
          tableName,
          columnName,
          FirstFilterColumn
        );
        if (type === "Sponsor") {
          setSponsors(data);
        } else if (type === "Scholarship") {
          setScholarships(data);
        }
      } catch (error) {
        console.error("Error Scholarship By Id :", error);
      }
    };

    Fill_Filters(
      "AUD_Enrolment_0310_1_Excel_xlsx",
      "scholarship_organization_name___corrected_",
      "Sponsor"
    );

    selectedSponsor &&
      Fill_Filters(
        "AUD_Enrolment_0310_1_Excel_xlsx",
        "scholarship_name_api",
        "Scholarship",
        {
          columnName: "scholarship_organization_name___corrected_",
          value: selectedSponsor,
        }
      );
  }, [selectedSponsor]);

  //---------------------------------------------------------------------

  return (
    <div className={`${styles.container} row`}>
      <div className={`${styles.selectContainer}`}>
        <h4>Sponsor</h4>
        <Form.Select
          id="Sponsor"
          className="input-bordered field-input"
          aria-label="Default select example "
          onChange={handleChangeScholarship}
          name="scholarship_organization_name___corrected_"
          value={selectedSponsor}
        >
          <option value={0}>All Sponsors</option>
          {sponsors &&
            sponsors.map((item) => {
              return (
                item &&
                item !== "0" && (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              );
            })}
        </Form.Select>
      </div>{" "}
      <div className={`${styles.selectContainer1}`}>
        <h4>Scholarship</h4>

        <Form.Select
          id="Scholarship"
          className="input-bordered field-input"
          aria-label="Default select example "
          onChange={handleChangeScholarship}
          name="scholarship_name_api"
          value={selectedScholarship}
        >
          <option value={0}>All Scholarships</option>
          {scholarships &&
            scholarships.map((item) => {
              return (
                item &&
                item !== "0" && (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              );
            })}
        </Form.Select>
      </div>{" "}
      {/* ------------------cardsContainerTop------------------ */}{" "}
      <div className={`${styles.containerFull}  row`}>
        <div className={`${styles.containerLeft}`}>
          {statistics &&
            statistics.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.card} ${
                    leftSelected === index ? styles.active : ""
                  }`}
                  onClick={() => setLeftSelected(index)}
                >
                  {withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setLeftSelected(index);
                        setAskQueryModal(true);
                        setQueryId(item.index);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={item.Card1Icon} />
                  <div>
                    <label className={`${styles.child} `}>{item.title}</label>
                    <h2 className={`${styles.child} `}>
                      {formatNumber(item.value)}
                    </h2>
                    <span className={`${styles.spanChild} `}>
                      {item.percentage}%
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className={`${styles.containerRight}`}>
          <Table
            loadingTable={loadingTable}
            tableName={"Total Applicant Scholar per Schools"}
            headerArray={headerArray}
            ActionColumn={false}
            smallTable
            showPaginator={false}
            defaultPerPage={10}
          />
        </div>
      </div>
      {/* ------------------cardsContainerTop------------------ */}
      {askQueryModal ? (
        <QueryStaticsModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          queryId={queryId}
          viewQueryResult={viewQueryResult}
          setStatistics={setStatistics}
          statistics={statistics}
        />
      ) : null}
    </div>
  );
};
