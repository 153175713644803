import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import BarGraph from "../../DashboardElements/Charts/barGraph";
import QueryModal from "../../DashboardElements/QueryModal";
import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { format } from "date-fns";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Graph = ({
  title,
  height,
  editMode,
  viewQueryResult,
  queryResult,
  index,
  reloadDashboard,
  graphType,
  titles,
  queryStructures,
  widgetType,
  seriesTitles,
  stacked = false,
  max = 100,
  data,
  pre = "",
}) => {
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [query, setQuery] = useState();
  const [maxValue, setMaxValue] = useState(max);

  const [defaultQueryStructure, setDefaultQueryStructure] = useState(
    queryStructures[0]
  );
  const [colors, setColors] = useState(["#05BC86", "#9FD5F3"]);
  const [allSeries, setAllSeries] = useState({
    tab1: [],
    tab2: [],
  });
  const [allCategories, setAllCategories] = useState({
    tab1: [],
    tab2: [],
  });
  const [categories, setCategories] = useState(allCategories.tab1);
  const [series, setSeries] = useState(allSeries.tab1);
  const [tabIndex, setTabIndex] = useState(index[0]);
  //----------------------------------------------------
  const [tabs, setTabs] = useState([
    {
      index: index[0],
      title: titles[0],
      width: "49%",
      active: true,
      categories: allCategories.tab1,
      series: allSeries.tab1,
      hasError: false,
    },
    {
      index: index[1],
      title: titles[1],
      width: "49%",
      active: false,
      categories: allCategories.tab2,
      series: allSeries.tab2,
      hasError: false,
    },
  ]);
  //---------------------------------------------------
  const getValueByIndex = (array, i) => {
    const item = array.find((element) => element.queryId === i);
    return item ? item.content : "no";
  };

  function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }
  //---------------------------------------------------
  const getObjByIndex = (array, i) => {
    const item = array.find((element) => element.title === i);
    return item ? item : "noObject";
  };
  //---------------------------------------------------

  function setActiveTab(arr, Value) {
    if (tabIndex !== getObjByIndex(arr, Value).index) {
      const array = [...arr];
      for (var i = 0; i < array.length; i++) {
        array[i]["active"] = false;
      }
      let a;
      for (var i = 0; i < array.length; i++) {
        if (array[i].title === Value) {
          array[i].active = true;
          setCategories(array[i].categories);
          setSeries(array[i].series);
          setTabIndex(index[i]);
          a = i;
          break;
        }
      }

      setDefaultQueryStructure(queryStructures[a]);
      setTabs(array);
    }
  }
  //----------------------------------------------------
  const handleSave = () => {
    let Query = {
      queryId: tabIndex,
      queryType: "report",
      widgetType: widgetType[index.indexOf(tabIndex)],
      queryName: query.queryName,
      queryText: query.query,
      reportId: query.reportId,
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
      platformName: "ASI",
    };
    // console.log(Query);
    if (query.queryName && query.query && query.reportId) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewQueryResult(tabIndex);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {});
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };
  //---------------------------------------------------
  const calculateSum = (arr) => {
    let total = 0;
    arr.forEach((element) => {
      total += element;
    });
    return total;
  };
  //----------------------------------------------
  useEffect(() => {
    viewQueryResult();
  }, [reloadDashboard]);

  ///**********************
  const isArrayofJSONObjects = (variable) => {
    if (!Array.isArray(variable)) {
      return false;
    }

    return variable.every(
      (item) =>
        typeof item === "object" && item !== null && !Array.isArray(item)
    );
  };
  //----------------------------------------------

  useEffect(() => {
    for (let i = 0; i < data.length; i++) {
      const queryResult = data[i];
      if (!isArrayofJSONObjects(queryResult.content)) {
        let array = [...tabs];
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          if (element.index === queryResult.queryId) {
            element.hasError = true;
            break;
          }
        }
        setTabs(array);
        setSeries([]);
        setCategories([]);
      }

      if (
        queryResult &&
        queryResult.queryId === tabIndex &&
        queryResult.content.length
      ) {
        //------------ update tabs
        let array = [...tabs];
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          if (element.index === queryResult.queryId) {
            element.hasError = false;
            break;
          }
        }
        setTabs(array);
        //------------ update tabs
        let result = queryResult.content;
        let val = [];
        if (result !== "no" && isArrayofJSONObjects(result)) {
          let cat = [];
          for (let j = 0; j < result.length; j++) {
            const element = result[j];

            cat.push(
              widgetType[index.indexOf(tabIndex)] !== "LineDateGraph"
                ? `${pre}${element["xAxis"]}`
                : format(new Date(element["xAxis"]), "MMMM dd") //MMMM dd, yyyy
            );
          }
          let arrayOfSeries = [];
          for (let j = 0; j < seriesTitles.length; j++) {
            const eachTitle = seriesTitles[j];
            let ser = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];
              ser.push(element["yAxis"][j]);
              val.push(calculateSum([...element.yAxis]));
            }

            arrayOfSeries.push({
              name: eachTitle,
              data: ser,
            });
          }

          setSeries(arrayOfSeries);
          setCategories(cat);
        }

        setMaxValue(Math.max(...val));
        break;
      }
    }
  }, [data, tabIndex]);

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedGraphQuery("ASI");
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === tabIndex) {
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          }
        }
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();
  }, [tabIndex]);
  //---------------------------------------------------

  return (
    <>
      <BarGraph
        withQuery={editMode}
        setAskQueryModal={setAskQueryModal}
        height={height}
        title={title}
        Gtype={graphType}
        tabs={tabs}
        showtoolbar={false}
        colors={colors}
        series={series}
        categories={categories}
        handleSave={handleSave}
        setActiveTab={setActiveTab}
        stacked={stacked}
        max={maxValue}
        pre={pre}
        queryStructures={queryStructures}
      />

      {askQueryModal ? (
        <QueryModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          handleSave={handleSave}
          setCategories={setCategories}
          setSeries={setSeries}
          categories={categories}
          series={series}
          defaultQueryStructure={defaultQueryStructure}
        />
      ) : null}
    </>
  );
};

export default Graph;
