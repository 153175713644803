import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import PieModal from "../PieModal";

const ApexPieChart = ({
  series,
  categories,
  height,
  selectedFilters,
  groupByColumn,
}) => {
  // Labels for the pie chart

  const [askQueryModal, setAskQueryModal] = useState(false);
  const [selectedSlice, setSelectedSlice] = useState(null);

  const [chartOptions, setChartOptions] = useState({
    labels: categories,
    chart: {
      type: "pie",
      height: 300,
    },
    responsive: [
      {
        breakpoint: 470, // For tablet or mobile
        options: {
          chart: {
            width: 200, // Adjust chart width for smaller screens
          },
          legend: {
            position: "right", // Change legend position
          },
        },
      },
    ],
  });

  //-----------------------------------------------------------
  useEffect(() => {
    // console.log(categories);

    setChartOptions((prevOptions) => ({
      labels: categories,
      colors: [
        "#FF6F61",
        "#6B5B95",
        "#88B04B",
        "#F7CAC9",
        "#92A8D1",
        "#955251",
        "#B565A7",
        "#009B77",
        "#DD4124",
        "#45B8AC",
        "#EFC050",
        "#5B5EA6",
        "#9B2335",
        "#DFCFBE",
        "#BC243C",
        "#98B4D4",
        "#C3447A",
        "#BFD641",
        "#7FCDCD",
        "#D65076",
      ],
      chart: {
        type: "pie",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // Get the index of the clicked slice
            const sliceIndex = config.dataPointIndex;
            // Set the selected slice to show in the modal
            setSelectedSlice(categories[sliceIndex]);
            // Show the modal
            setAskQueryModal(true);
          },
        },
        height: 400,
        width: 600,
      },
      responsive: [
        {
          breakpoint: 470, // For tablet or mobile
          options: {
            chart: {
              width: 400, // Adjust chart width for smaller screens
            },
            legend: {
              position: "bottom", // Change legend position
            },
          },
        },
      ],
    }));
  }, [categories]);
  //------------------------------------------------------------------
  return (
    <div className="chart " style={{ padding: "3% 11%" }}>
      <Chart
        options={chartOptions}
        series={series && series.length && series[0].name && series[0].data}
        type="pie"
        // height={700}
        width={chartOptions.chart.width}
        height={chartOptions.chart.height}
      />

      {askQueryModal ? (
        <PieModal
          setAskQueryModal={setAskQueryModal}
          selectedSlice={selectedSlice}
          selectedFilters={selectedFilters}
          groupByColumn={groupByColumn}
        />
      ) : null}
    </div>
  );
};

export default ApexPieChart;
