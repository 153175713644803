import GetBaseUrl from "./SetBaseUrl";
import axios from "axios";

const API_BASE_URL = GetBaseUrl()[0];
const ChatGPT_API_BASE_URL = GetBaseUrl()[3];
const GPT_token = localStorage.getItem(GetBaseUrl()[1] + "_GPT_token");
const token1 =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJzdWIiOiJBSUNoYXRBY2Nlc3NUb2tlbiIsImp0aSI6Ijk4OTVhY2RkLWZhYjAtNDllYS05ZDdjLWYyNmVjOTVjYjNmMCIsImlhdCI6IjgvMjgvMjAyNCA5OjI3OjI0IFBNIiwiVXNlcm5hbWUiOiJjdHNBZG1pbiIsIlVzZXJJZCI6IjI1YzZiYzRjLWI3YTctNGZkOC1hMDRlLWQ1YmRmNGI1Mzk4MiIsIkNvbXBhbnlJZCI6ImVlMWRiYzZjLTAwODctNGNjZC05MTA3LTQ2MTkyZDFmZjMzYiIsImV4cCI6MTcyNDg2NjA0NCwiaXNzIjoiaHR0cHM6Ly9haS5jdHNkZW1vLmFlIiwiYXVkIjoiaHR0cHM6Ly9haS5jdHNkZW1vLmFlIn0.UWmEst8lV5zftcNTYZOuZS4_38O1W84X2HDhRKW_cic";
export const GetAPIList = async (type, orderBy = "") => {
  let url = "";
  if (type === "tables") {
    url = "/Data/GetTables";
  }
  if (type === "reports") {
    url = "/Report/GetSavedReports";
  }
  let arr = [];
  let newArr = [];
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);
    if (orderBy) {
      arr = response.data.sort((a, b) => a[orderBy] - b[orderBy]);
    } else {
      arr = response.data;
    }
    arr.map((item) => {
      type === "reports"
        ? newArr.push({ Id: item.relId, Name: item.reportName })
        : newArr.push({ Id: item.tableId, Name: item.tableName });
    });
    return newArr;
  } catch (error) {
    console.error("Error fetching:", error);
    throw error;
  }
};

export const GetAPIById = async (type, id, pageIndex = 1, pageSize = 10) => {
  let url = "";
  if (type === "tables") {
    url = `/Data/GetDataByTable/${id}/${pageIndex}/${pageSize}`;
  }
  if (type === "reports") {
    url = "/Report/GetReportById/" + id;
  }
  let arr = [];
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);
    arr = response.data;
    return arr;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};

export const GetReportColumnsById = async (id, pageIndex = 1, pageSize = 1) => {
  let url = "";
  url = `/Report/GetReportById/${id}/${pageIndex}/${pageSize}`;
  let arr = [];
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);
    arr = response.data.columns;

    return arr;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};

//-------------------------------------------------------
export const GetAPISavedStaticsQuery = async (platformName) => {
  let url = `/Report/GetReportQuery/statistics?platformName${platformName}`;
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);

    return response.data;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};
export const GetAPISavedFilterQuery = async (platformName) => {
  let url = `/Report/GetReportQuery/table?platformName=${platformName}`;
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);

    return response.data;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};
export const GetAPISavedGraphQuery = async (platformName) => {
  let url = `/Report/GetReportQuery/report?platformName=${platformName}`;
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);

    return response.data;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};
//-------------------------------------------------------
export const GetAPIFilterData = async (
  filterColumn,
  pageIndex = 1,
  pageSize = 10000
) => {
  let url = `/Data/GetDistinctColumns/${filterColumn}/${pageIndex}/${pageSize}`;
  let arr = [];
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);
    // console.log(response.data.data);
    response.data.data.map(
      (item) =>
        item && arr.push({ value: item, label: item, columnId: filterColumn })
    );
    return arr;
  } catch (error) {
    console.error("Error BYId:", error);
    throw error;
  }
};
//------------------------------
export const GetAPIFilterDataByColumnName = async (
  tableName,
  columnName,
  asperColumnValue = {
    columnName: "",
    value: "",
  },
  pageIndex = 1,
  pageSize = 10000
) => {
  let url = `/Data/GetDistinctColumnsByName/${tableName}/${columnName}/${pageIndex}/${pageSize}?filterColumn=${asperColumnValue.columnName}&filterValue=${asperColumnValue.value}`;
  let arr = [];
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`);

    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

//-------------------------------------------------------
export const GetChatGPT = async (msg) => {
  try {
    const response = await axios.post(
      `${ChatGPT_API_BASE_URL}/Chat/ChatGPT`,
      {
        chatId: "0b08d1b1-a101-45a1-a627-80258363735b",
        chatType: 0,
        isInternet: true,
        oldPrompt: "CTS In Dubai",
        prompt: msg,
      },
      {
        headers: {
          Authorization: `Bearer ${GPT_token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("GetChatGPT: ", error);
    throw error;
  }
};
