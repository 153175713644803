import React, { useRef, useState, useEffect } from "react";
import { Form, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import UpdateTable from "../modals/UpdateTable";
import Rename from "../modals/Rename";
import AssignToGroup from "../modals/AssignToGroup";
import Delete from "../modals/Delete";
import Unassign from "../modals/Unassign";
import canvas_ic from "../../assets/svg/canvas-ico.svg";
import office_ic from "../../assets/svg/office-ico.svg";
import bb_ic from "../../assets/svg/bb-ico.svg";
import { Rings } from "react-loader-spinner";
import csv from "../../assets/svg/csv.svg";

const TableXls = (props) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-content-center tbl my-2 ">
        <div className="d-flex align-items-center w-70 pointer">
          <Form.Check
            type="checkbox"
            className=" w-5 fs-6"
            onChange={props.handleSelectAllChange}
            checked={props.selectAllCheckbox}
            onClick={(e) => props.open && props.onClick(e)}
          />
          <i className="edu ic-tbl fs-7 mx-3"></i>
          <span className="fs-7 text-truncate1" title={props.tableName}>
            {" "}
            {props.tableName.length <= 42
              ? props.tableName
              : props.tableName.substr(0, 42) + "..."}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TableXls;
