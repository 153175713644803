import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { GetAPIList, GetAPIById } from "../../functions/GetAPIList";
import { extractStringsBetweenBrackets } from "../../functions/FunctionsStore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

function Blackboard(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  const [columns, setColumns] = useState([]);
  const [filterTitle, setFilterTitle] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedSource, setSelectedSource] = useState("");

  const [reportsList, setReportsList] = useState([]);
  const [output, setOutput] = useState();

  const handleChangeSource = (event) => {
    setSelectedSource(event.target.value);
  };
  const handleChangeColumn = (event) => {
    setSelectedColumn(event.target.value);
  };
  const handleChange = (event) => {
    setFilterTitle(event.target.value);
  };
  const handleClose = () => props.setAskQueryModal(false);

  useEffect(() => {
    //console.log(reportsList);

    for (let i = 0; i < props.selectedFilters.length; i++) {
      const element = props.selectedFilters[i];
      props.queryId === element.queryId && setFilterTitle(element.queryName);
      setSelectedSource(element.tableId);
    }
  }, [reportsList]);
  //---------------------------------------------------  (1)
  useEffect(() => {
    const getReportsList = async () => {
      try {
        const data = await GetAPIList("tables", "apiId");
        setReportsList(data);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };

    getReportsList();

    // console.log(props.selectedFilters);
    // console.log(props.queryId);
  }, []);

  //---------------------------------------------------  (2)
  useEffect(() => {
    // console.log(props.query);
    if (selectedSource) {
      let Id = selectedSource;
      const getReportResult = async (Id) => {
        try {
          const data = await GetAPIById("tables", Id, 1, 10);
          setOutput(data);
        } catch (error) {
          console.error("Error By Id :", error);
        }
      };

      getReportResult(Id);
    }
  }, [selectedSource]);
  //---------------------------------------------------  (2)
  useEffect(() => {
    if (output) {
      //   console.log(output);
      setColumns(output.columns);
    }
  }, [output]);
  //---------------------------------------------------  (2)
  useEffect(() => {
    if (columns) {
      for (let i = 0; i < props.selectedFilters.length; i++) {
        const element = props.selectedFilters[i];
        props.queryId === element.queryId &&
          setSelectedColumn(element.columnId);
      }
    }
  }, [columns]);

  //---------------------------------------------------
  const runQuery = () => {
    props.setFilterCol(selectedColumn);
    handleSave();
    handleClose();
  };
  //----------------------------------------------------
  const handleSave = () => {
    let Query = {
      queryId: props.queryId,
      queryType: "table",
      queryName: filterTitle,
      queryText: "",
      reportId: 0,
      tableId: selectedSource,
      columnId: selectedColumn,
      platformName: "AUD",
    };
    // console.log(Query);

    axios
      .post(BaseUrl + "/Report/InsertReportQuery", Query, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Filter query has been added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  //---------------------------------------------------

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="md"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>Edit Filter</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Title</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="Enter Title"
                name="queryName"
                value={filterTitle}
                onChange={handleChange}
              />
            </div>

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Filter Table </label>
              <Form.Select
                className="input-bordered field-input mb-4 "
                aria-label="Default select example "
                onChange={handleChangeSource}
                value={selectedSource}
              >
                <option>Select Table</option>
                {reportsList &&
                  reportsList.map((report) => {
                    return (
                      <option key={report.Id} value={report.Id}>
                        {report.Name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Filter Source </label>
              <Form.Select
                className="input-bordered field-input mb-4 "
                aria-label="Default select example "
                onChange={handleChangeColumn}
                name="selectedColumn"
                value={selectedColumn}
              >
                <option>Select Column</option>
                {columns &&
                  columns.map((column) => {
                    return (
                      <option key={column.columnId} value={column.columnId}>
                        {column.columnName}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>

            <div className={`${PIS["container-100-100-100"]} hide `}>
              <label className="fs-7 fw-semibold"> Output</label>
              <Form.Control
                as="textarea"
                rows={15}
                className="input-bordered p-3 py-2   mb-4"
                value={output}
              />
            </div>

            <Button
              className="main-button w-100 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => runQuery()}
            >
              Run Query
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Blackboard;
