import React, { useEffect, useState, useContext } from "react";
import styles from "./ToggleColumnsVisibility.module.scss";
import Select, { components } from "react-select";
import EmiratesIcon from "../../assets/svg/EmiratesIcon.svg";
import SchoolIcon from "../../assets/svg/schoolIcon.svg";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import CheckedIcon from "../../assets/svg/CheckedIcon.svg";
import UnCheckedIcon from "../../assets/svg/UnCheckedIcon.svg";

import GetBaseUrl from "../../functions/SetBaseUrl";

const Toggle = ({ icon, setTableColumns, tableColumns }) => {
  var role = localStorage.getItem(GetBaseUrl()[1] + "_Role");

  const handleVisibility = (name, newValue) => {
    const updatedData = tableColumns.map((item) =>
      item.name === name ? { ...item, visible: newValue } : item
    );
    setTableColumns(updatedData);
  };

  return (
    <Dropdown as={ButtonGroup} autoClose="outside">
      <Dropdown.Toggle split variant="" id="dropdown-split-basic" className="">
        <img src={icon} alt="aa" id="dropdown-split-basic" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${styles["DropdownContainer"]} more-drop`}>
        <>
          {tableColumns &&
            tableColumns.map((item, index) => {
              return (
                <Dropdown.Item
                  href="#"
                  key={index}
                  className={`${styles["DropdownItem"]} row`}
                  onClick={() => {
                    handleVisibility(item.name, !item.visible);
                  }}
                >
                  <span>{item.name}</span>
                  <img
                    src={item.visible ? CheckedIcon : UnCheckedIcon}
                    alt="Column Visibility"
                  />
                </Dropdown.Item>
              );
            })}
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Toggle;
