import React, { useEffect, useState, useContext } from "react";
import styles from "./Programs.module.scss";
import "react-toastify/dist/ReactToastify.css";
import CheckedIcon from "../../../assets/svg/CheckedIcon.svg";
import UnCheckedIcon from "../../../assets/svg/UnCheckedIcon.svg";
import ProgramGraphQuery from "../Graphs/ProgramGraphQuery";
import EditQuery from "../../../assets/images/EditQuery.svg";
import QueryModal from "../QueryModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import axios from "axios";
import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";

export const Programs = ({
  queryResult,
  QS,
  reloadDashboard,
  setEditMode,
  editMode,
  viewQueryResult,
  selectedFilters,
}) => {
  //---------------------------------------------------------------------
  const [tabIndex, setTabIndex] = useState(41);
  const [percentage, setPercentage] = useState(true);
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  //------------------------------------------------------------
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [query, setQuery] = useState(null);
  const getObjByKey = (array, i, key) => {
    const item = array.find((element) => element[key] === i);
    return item ? item : "";
  };

  let queryStructures2_1 = `Select
Count 
Where - program_type='Undergraduate'
Group By - programdescrip,my_status`;

  let queryStructures2_2 = `Select
Count 
Where - program_type='Graduate'
Group By - programdescrip,my_status`;

  let queryStructures2_3 = `Select
Count 
Where - program_type='Others'
Group By - programdescrip,my_status`;

  const [programCharts, setProgramCharts] = useState([
    {
      index: [41],
      titles: [],
      key: 41,
      widgetType: ["LineStackPercentageGroupGraph"],
      graphType: "bar",
      seriesTitles: [
        "Applicant Pending - Reject",
        "Application Closed",
        "Cancel",
        "Enrolled",
        "No Show",
        "Postpone",
        "Reject",
      ],
      max: 100,
      height: 400,
      title: "",
      stacked: true,
      pre: "",
      options: [queryStructures2_1],
    },
    {
      index: [42],
      titles: [],
      key: 42,

      widgetType: ["LineStackPercentageGroupGraph"],
      graphType: "bar",
      seriesTitles: [
        "Applicant Pending - Reject",
        "Application Closed",
        "Cancel",
        "Enrolled",
        "No Show",
        "Postpone",
        "Reject",
      ],
      max: 100,
      height: 400,
      title: "",
      stacked: true,
      pre: "",
      options: [queryStructures2_2],
    },
    {
      index: [43],
      titles: [],
      key: 43,

      widgetType: ["LineStackPercentageGroupGraph"],
      graphType: "bar",
      seriesTitles: [
        "Applicant Pending - Reject",
        "Application Closed",
        "Cancel",
        "Enrolled",
        "No Show",
        "Postpone",
        "Reject",
      ],
      max: 100,
      height: 400,
      title: "",
      stacked: true,
      pre: "",
      options: [queryStructures2_3],
    },
  ]);
  const [defaultQueryStructure, setDefaultQueryStructure] = useState(
    programCharts[0].options[0]
  );

  //----------------------------------------------------
  useEffect(() => {
    const SavedGraphQuery = async () => {
      let qName = "";
      let qReportId = 0;
      let qQuery = "";
      try {
        const data = await GetAPISavedGraphQuery("AUD");

        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === tabIndex) {
            qName = element.queryName;
            qReportId = element.reportId;
            qQuery = element.queryText;
          }
        }

        setQuery({
          queryName: qName,
          reportId: qReportId,
          query: qQuery,
        });
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();
    viewQueryResult([tabIndex], selectedFilters);
  }, [tabIndex, percentage]);
  //---------------------------------------------------

  const handleSave = () => {
    let Query = {
      queryId: tabIndex,
      queryType: "report",
      widgetType: getObjByKey(programCharts, tabIndex, "key").widgetType[0],
      queryName: query.queryName,
      queryText: query.query,
      reportId: query.reportId,
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
      platformName: "AUD",
    };
    // console.log(Query);
    if (query.queryName && query.query && query.reportId) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewQueryResult([tabIndex], selectedFilters);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {});
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  //----------------------------------------------
  useEffect(() => {
    // console.log(selectedFilters);

    viewQueryResult([41, 42, 43], selectedFilters);
  }, [selectedFilters]);
  //--------------------------------
  return (
    <div className={`${styles.container} row Focus`}>
      <h4>Programs</h4>
      <div className={`${styles.selectContainer} row Focus`}>
        <span
          className={`${styles.programType}  ${
            tabIndex === 41 && styles.active
          }`}
          onClick={() => {
            setTabIndex(41);
            setDefaultQueryStructure(programCharts[0].options[0]);
            setPercentage(true);
          }}
        >
          Undergraduate
          {editMode && (
            <>
              <img
                src={EditQuery}
                onClick={() => setAskQueryModal(true)}
                className={`${styles["EditQuery"]}`}
                alt="EditQuery"
              />
            </>
          )}{" "}
        </span>

        <span
          className={`${styles.programType} ${
            tabIndex === 42 && styles.active
          }`}
          onClick={() => {
            setTabIndex(42);
            setDefaultQueryStructure(programCharts[1].options[0]);
            setPercentage(true);
          }}
        >
          Graduate
          {editMode && (
            <>
              <img
                src={EditQuery}
                onClick={() => setAskQueryModal(true)}
                className={`${styles["EditQuery"]}`}
                alt="EditQuery"
              />
            </>
          )}{" "}
        </span>
        <span
          className={`${styles.programType} ${
            tabIndex === 43 && styles.active
          }`}
          onClick={() => {
            setTabIndex(43);
            setDefaultQueryStructure(programCharts[2].options[0]);
            setPercentage(true);
          }}
        >
          Others
          {editMode && (
            <>
              <img
                src={EditQuery}
                onClick={() => setAskQueryModal(true)}
                className={`${styles["EditQuery"]}`}
                alt="EditQuery"
              />
            </>
          )}{" "}
        </span>
      </div>{" "}
      <div className={`${styles.percentage} `}>
        <img
          src={percentage ? CheckedIcon : UnCheckedIcon}
          alt="CheckedIcon"
          onClick={() => setPercentage(!percentage)}
        />{" "}
        <span>Percentage</span>
      </div>
      <div className={`${styles.programCharts} `}>
        {programCharts &&
          programCharts.map((graph) => {
            return (
              <ProgramGraphQuery
                percentage={percentage}
                showGraph={graph.index.indexOf(tabIndex) > -1}
                fullWidthContainer
                withTabs={false}
                data={queryResult}
                setEditMode={setEditMode}
                editMode={false}
                viewQueryResult={viewQueryResult}
                reloadDashboard={reloadDashboard}
                key={graph.index}
                height={graph.height}
                title={graph.title}
                widgetType={graph.widgetType}
                index={graph.index}
                titles={graph.titles}
                graphType={["bar"]}
                // graphType={graph.graphType}
                queryStructures={graph.options}
                seriesTitles={graph.seriesTitles}
                max={graph.max}
                stacked={graph.stacked}
                pre={graph.pre}
                maxCategoryLength={20}
              />
            );
          })}
      </div>
      {askQueryModal ? (
        <QueryModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          handleSave={handleSave}
          setCategories={setCategories}
          setSeries={setSeries}
          categories={[]}
          series={[]}
          defaultQueryStructure={defaultQueryStructure}
        />
      ) : null}
    </div>
  );
};
