import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { useLottie } from "lottie-react";
import loading_lottie from "../../assets/lottie/loading.json";

import axios from "axios";

const TableResult = ({
  setSyncing,
  TableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewTable,
  setViewTableName,
  HeaderType,
  DownloadExcel,
  tableRef,
  Syncing,
  exportTable,
  isValidating = false,
}) => {
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [LastUpdate, setLastUpdate] = useState("");
  const [HasError, setHasError] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [allRecords, setAllRecords] = useState(10000000); // For fetch all records by pagination

  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState(0);
  const [sortType, setSortType] = useState("ASC");
  const [reloadTable, setReloadTable] = useState(0);

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const options = {
    animationData: loading_lottie,
    loop: true,
  };

  const { View } = useLottie(options);

  //---------------------------------------------
  var refreshIntervalId = setInterval(checkTableSyncing(TableId), 4000);
  function checkTableSyncing(TableId) {
    axios
      .get(BaseUrl + "/Data/CheckTableSyncing/" + TableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setSyncing(res.data);
        if (!res.data.syncing) {
          clearInterval(refreshIntervalId);
        }
      })
      .catch((error) => {});
  }
  //------------------------------------------
  useEffect(() => {
    // console.log(searchText);
    // setSortType(sortType === "ASC" ? "DESC" : "ASC");
  }, [searchText]);

  useEffect(() => {
    setPageIndex(1);
    setPageSize(10);
    setSortBy("");
    setReloadTable(Math.random(500));
  }, [TableId]);

  useEffect(() => {
    let SyncingNow = false;
    let tableHead = [""];
    let tablebodyCol = [];

    let queryParam = ``;
    if (searchText && sortBy) {
      queryParam = `?SearchQuery=${searchText}&SelectedColumn=${sortBy}&SortDirection=${sortType}`;
    } else if (searchText && !sortBy) {
      queryParam = `?SearchQuery=${searchText}`;
    } else if (!searchText && sortBy) {
      queryParam = `?SelectedColumn=${sortBy}&SortDirection=${sortType}`;
    }

    // console.log(queryParam);
    if (TableId) {
      setTAbleUrl(
        `${BaseUrl}/Data/GetDataByTable/${TableId}/${pageIndex}/${
          pageSize !== -1 ? pageSize : allRecords
        }${queryParam}`
      );

      axios
        .get(
          `${BaseUrl}/Data/GetDataByTable/${TableId}/${pageIndex}/${
            pageSize !== -1 ? pageSize : allRecords
          }${queryParam}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setViewTableName(res.data.tableName);

          setTotalRows(res.data.totalRecords);
          setTableName(res.data.tableName);
          setLastUpdate(res.data.updatedOn);

          res.data.columns.map((item, index) =>
            tableHead.push(item["columnName"])
          );
          res.data.columns.map((item, index) => tablebodyCol.push("rowData"));
        })
        .finally(() => {
          setTableHead(tableHead);
          setBodyCol(tablebodyCol);
          setSyncing(SyncingNow);
        });
    }
  }, [reloadTable, pageIndex, pageSize, sortBy, sortType, searchText]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loading={View}
          isValidating={isValidating}
          TableTitle={tableName}
          TableId={TableId}
          TableUrl={tableUrl}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader={HeaderType}
          firstColumn
          Internal={true}
          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewTable={setViewTable}
          DownloadExcel={DownloadExcel}
          tableRef={tableRef}
          LastUpdate={LastUpdate}
          HasError={HasError}
          setHasError={setHasError}
          Syncing={Syncing}
          exportTable={exportTable}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalRows={totalRows}
          searchText={searchText}
          setSearchText={setSearchText}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortType={sortType}
          setSortType={setSortType}
        />
      ) : null}
    </>
  );
};

export default TableResult;
