import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../functions/SetBaseUrl";
import styles from "./DashboardElements/Charts/Chart.module.scss";

import MultiGraphQuery from "./DashboardElements/Graphs/MultiGraphQuery";
import GraphQuery from "./DashboardElements/Graphs/GraphQuery";
import DashboardHeader from "./DashboardElements/DashboardHeader";
import DashboardStatics from "./DashboardElements/DashboardStatics";
import "./DashboardElements/Dashboard.css";
import { TableComponent } from "./DashboardElements/Charts/Table";

import "react-toastify/dist/ReactToastify.css";
import UserType1 from "../assets/svg/Dashboard/UserType1.svg";
import UserType2 from "../assets/svg/Dashboard/UserType2.svg";
import UserType3 from "../assets/svg/Dashboard/UserType3.svg";
import UserType4 from "../assets/svg/Dashboard/UserType4.svg";

import ActiveStar from "../assets/svg/Dashboard/ActiveStar.svg";
import InactiveStar from "../assets/svg/Dashboard/InactiveStar.svg";
import { IndexContext } from "../components/layout/Layout";

import axios from "axios";
const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Dashboard = () => {
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("Dashboard");

  const [editMode, setEditMode] = useState(false);
  const [tabIndex, setTabIndex] = useState(340);
  const [graphs, setGraphs] = useState([]);
  const [multiGraphs, setMultiGraphs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [reloadDashboard, setReloadDashboard] = useState(0);
  //----------------------------------------------------
  const [tabs, setTabs] = useState([
    {
      index: 340,
      title: "Student Feedback",
      width: "18%",
      active: true,
    },
    {
      index: 350,
      title: "Educators Feedback",
      width: "18%",
      active: false,
    },
    {
      index: 370,
      title: "Training Feedback",
      width: "18%",
      active: false,
    },
  ]);
  //-------------------------------------------------------
  let queryStructures1_1 = `Select
  Count
  Group By - Created_at`;

  let queryStructures1_2 = `Select
  Count
  Group By - Created_at`;

  let queryStructures2_1 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures2_2 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures3_1 = `Select
  Count
  Group By - visitdate`;

  let queryStructures3_2 = `Select
  Count
  Where - Trainingtype='True'
  Group By - visitdate`;

  let queryStructures4 = `Select -  Visitdate, Schoolname,  SUM(Teachercount,Sltcount,Parentcount,Studentcount) Attendees, Rating
Order By - VisitId`;
  const [tableQueryStructures, setTableQueryStructures] = useState([
    {
      options: [queryStructures4],
    },
  ]);
  //-------------------------------------------------------
  const [queryStructures, setQueryStructures] = useState([
    {
      index: [301, 302],
      titles: ["Count of Prompts", "Count of Conversations"],
      widgetType: ["LineDateGraph", "LineDateGraph"],
      graphType: "area",
      seriesTitles: ["Active"],
      max: 100,
      height: 417,
      title: "",
      stacked: false,
      pre: "",
      options: [queryStructures1_1, queryStructures1_2],
    },
    {
      index: [310, 311],
      titles: ["Active Students", "Active Students Count"],
      widgetType: ["LineStackPercentageGraph", "LineStackGraph"],
      graphType: "bar",
      seriesTitles: ["Active", "total"],
      max: 100,
      height: 417,
      title: "",
      stacked: true,
      pre: "Grade",
      options: [queryStructures2_1, queryStructures2_2],
    },
    {
      index: [320, 321],
      titles: ["Visits Count", "Training Count"],
      widgetType: ["LineDateGraph", "LineDateGraph"],
      graphType: "bar",
      seriesTitles: ["Active"],
      max: 10,
      height: 417,
      title: "",
      stacked: false,
      pre: "",
      options: [queryStructures3_1, queryStructures3_2],
    },
  ]);

  //---------------------------------------------------setActiveTab
  function setActiveTab(arr, Value) {
    const array = [...arr];
    for (var i = 0; i < array.length; i++) {
      array[i]["active"] = false;
    }

    for (var i = 0; i < array.length; i++) {
      if (array[i].title === Value) {
        array[i].active = true;
        setTabIndex(array[i].index);
        break;
      }
    }

    setTabs(array);
  }
  //---------------------------------------------------
  const viewQueryResult = () => {
    setGraphs([]);
    getGraphResult([301, 302, 311, 310, 320, 321, 330]);
  };

  const viewMultiQueryResult = (array) => {
    setMultiGraphs([]);
    getGraphResult(array, true);
  };
  //---------------------------------------------------getGraphResult
  const getGraphResult = async (graphIndex, multi = false) => {
    let fullArray = [];
    for (let i = 0; i < graphIndex.length; i++) {
      const element = graphIndex[i];
      let obj = {
        queryId: element,
        filterData: selectedFilters,
      };
      try {
        const response = await axios.post(
          BaseUrl + "/Report/GetDashboardReportQueryData",
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        );

        fullArray.push({ queryId: element, content: response.data });
      } catch (error) {
        fullArray.push({
          queryId: element,
          content: [],
        });
      }
    }
    if (multi) {
      setMultiGraphs(fullArray);
    } else {
      setGraphs(fullArray);
    }
  };
  //----------------------------------------------getGraphResult

  return (
    <div className="rounded-0 Dashboard-container min-vh-85">
      <DashboardHeader
        setEditMode={setEditMode}
        editMode={editMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        viewQueryResult={viewQueryResult}
        setReloadDashboard={setReloadDashboard}
      />
      <DashboardStatics
        setEditMode={setEditMode}
        editMode={editMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        reloadDashboard={reloadDashboard}
        setReloadDashboard={setReloadDashboard}
      />
      <div className="charts-container row">
        {queryStructures &&
          queryStructures.map((graph) => {
            return (
              <GraphQuery
                height={graph.height}
                title={graph.title}
                widgetType={graph.widgetType}
                data={graphs}
                setEditMode={setEditMode}
                editMode={editMode}
                viewQueryResult={viewQueryResult}
                index={graph.index}
                titles={graph.titles}
                reloadDashboard={reloadDashboard}
                graphType={graph.graphType}
                queryStructures={graph.options}
                seriesTitles={graph.seriesTitles}
                max={graph.max}
                stacked={graph.stacked}
                pre={graph.pre}
              />
            );
          })}
        <TableComponent
          tableTitle={"Visits / Contacting List"}
          widgetType="List"
          queryStructures={tableQueryStructures[0].options}
          withQuery={editMode}
          index={[330]}
          viewQueryResult={viewQueryResult}
          queryResult={graphs}
        />
        {tabs && (
          <div className={`${styles.tabsContainer} row`}>
            {tabs.map((tab) => {
              return (
                <div
                  key={tab.title}
                  className={`${styles.tab} ${
                    tab.active ? styles.active : ""
                  } `}
                  onClick={() => setActiveTab(tabs, tab.title)}
                >
                  <span className={`${tab.active ? styles.active : ""}`}>
                    {tab.title}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <MultiGraphQuery
          height={417}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          viewMultiQueryResult={viewMultiQueryResult}
          data={multiGraphs}
          tabIndex={tabIndex}
          reloadDashboard={reloadDashboard}
        />
      </div>
    </div>
  );
};

export default Dashboard;
