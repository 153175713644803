import React, { useEffect, useState, useContext, Component } from "react";
import QueryError from "../../../components/modals/QueryError";
import PieGraph from "./pieGraph";
import FlagGraph from "./flagGraph";
import { Rings } from "react-loader-spinner";
import Modal from "react-modal";
import BarModal from "../barModal";

import ReactApexChart from "react-apexcharts";
import styles from "./barGraph.module.scss";
import EditQuery from "../../../assets/images/EditQuery.svg";

const ApexChartComponent = (props) => {
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [selectedSlice, setSelectedSlice] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const [shortenedCategories, setShortenedCategories] = useState([]);
  const [sortedSeries, setSortedSeries] = useState([]);

  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    series: [],
    options: {},
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const data = {
  //       series: props.series,
  //       options: {
  //         chart: {
  //           height: props.height,
  //           stacked: props.stacked,
  //           type: props.Gtype === "flag" ? "bar" : props.Gtype,
  //           toolbar: {
  //             show: props.showtoolbar, // Disable the export and download options
  //           },

  //           zoom: {
  //             enabled: false,
  //           },
  //         },

  //         plotOptions: {
  //           bar: {
  //             horizontal: false,
  //             columnWidth: "35%", // Adjusts the thickness of the bars
  //             borderRadius: 4, // Fully rounded bars
  //           },
  //         },

  //         legend: {
  //           position: "left", // Set the legend to the left
  //           offsetX: -20, // Adjust the position to avoid overlap with the chart
  //           markers: {
  //             width: 10,
  //             height: 10,
  //           },
  //         },

  //         colors: props.colors, // Custom colors for each series

  //         dataLabels: {
  //           formatter: (val) => `${val} `,
  //           offsetY: 0,
  //           style: {
  //             fontSize: "12px",
  //             colors: ["#FFF"],
  //           },
  //         },
  //         stroke: {
  //           curve: "smooth",
  //         },

  //         title: {
  //           text: `${props.title}  `,
  //           align: "center",
  //           style: {
  //             fontSize: "24px",
  //             fontWeight: "bold",
  //             color: "#263238",
  //             fontFamily: "Arial, sans-serif",
  //           },
  //         },
  //         grid: {
  //           row: {
  //             colors: ["#f3f3f3", "transparent"], //  takes an array which will be repeated on columns
  //             opacity: 0.5,
  //           },
  //         },

  //         xaxis: {
  //           categories: shortenedCategories,
  //         },
  //         yaxis: {
  //           min: 0,

  //           labels: {
  //             formatter: (val) => `${val} `,
  //           },
  //         },
  //       },
  //     };
  //   };

  //   // fetchData();
  // }, []);

  useEffect(() => {
    setShortenedCategories(
      props.categories.map((category) =>
        category.length > props.maxCategoryLength && props.maxCategoryLength
          ? category.slice(0, props.maxCategoryLength) + "..."
          : category
      )
    );

    let array = props.series && [...props.series];
    let maxNum = 10;
    let realArray = [];

    for (let i = 0; i < array.length; i++) {
      const element = array[i].data;
      realArray.push(element);
    }

    const numColumns = realArray && realArray[0] && realArray[0].length;
    // Create an array to hold the maximum values for each column
    const sumValues = Array(numColumns).fill(0);
    // Loop through each sub-array and find the max value for each position
    for (let i = 0; i < realArray.length; i++) {
      for (let j = 0; j < realArray[i].length; j++) {
        realArray[i][j] && (sumValues[j] += realArray[i][j]);
      }
    }

    maxNum < Math.max(...sumValues) && (maxNum = Math.max(...sumValues));

    props.percentage ? setMaxValue(100) : setMaxValue(maxNum);
  }, [props.categories, props.series, props.percentage]);

  //-----------------------------------------------------------

  const roundToNextHundred = (num) => {
    return Math.ceil(num / 10) * 10; // Rounds up to the nearest hundred
  };

  //------------------------
  useEffect(() => {
    // props.series &&
    //   setChartOptions((prevOptions) => ({
    //     ...prevOptions,
    //     options: {
    //       ...prevOptions.options,
    //       events: {
    //         dataPointSelection: (
    //           event,
    //           chartContext,
    //           config
    //         ) => {
    //           if (
    //             config.dataPointIndex !== undefined &&
    //             config.seriesIndex !== undefined
    //           ) {
    //             // Get the index of the clicked slice
    //             const sliceIndex = config.dataPointIndex;
    //             // Set the selected slice to show in the modal
    //             setSelectedSlice(props.categories[config.sliceIndex]);
    //             const dataValue =
    //               props.series &&
    //               props.series[config.seriesIndex].data[config.dataPointIndex];
    //             setSelectedData(dataValue);
    //             // Show the modal
    //             setAskQueryModal(true);
    //           } else {
    //             console.error("Invalid configuration passed to event.");
    //           }
    //         },
    //       },
    //     },
    //   }));
  }, [props.series]);

  useEffect(() => {
    // console.log(shortenedCategories.length && shortenedCategories);
    // console.log(props.series);
    // console.log(maxValue);

    const newNumber = props.percentage
      ? maxValue
      : roundToNextHundred(maxValue);

    shortenedCategories &&
      shortenedCategories.length &&
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: props.stacked ? props.series : props.series,

        options: {
          ...prevOptions.options,
          chart: {
            height: props.height,
            stacked: props.stacked,
            type: props.Gtype === "flag" ? "bar" : props.Gtype,
            toolbar: {
              show: props.showtoolbar, // Disable the export and download options
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                if (
                  config.dataPointIndex !== undefined &&
                  config.seriesIndex !== undefined
                ) {
                  // Get the index of the clicked slice
                  const sliceIndex = config.dataPointIndex;
                  // Set the selected slice to show in the modal
                  setSelectedSlice(props.categories[config.sliceIndex]);

                  const selectedBarData = props.series.map((serie, index) => ({
                    name: serie.name, // Legend title (series name)
                    data: [serie.data[config.dataPointIndex]], // Value for the clicked bar
                  }));

                  setSelectedData(selectedBarData);
                  setSelectedSlice(props.categories[sliceIndex]);

                  // Show the modal
                  setAskQueryModal(true);
                } else {
                  console.error("Invalid configuration passed to event.");
                }
              },
            },
            zoom: {
              enabled: false,
            },
          },

          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35%", // Adjusts the thickness of the bars
              borderRadius: props.stacked ? 4 : 8, // Fully rounded bars
            },
          },

          colors: props.colors, // Custom colors for each series

          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              // Calculate percentage relative to the total value of the series
              const total = opts.w.globals.seriesTotals.reduce(
                (a, b) => a + b,
                0
              );
              const per = props.percentage ? val + "%" : val;
              return per; // Show percentage on bars
            },
            offsetY: props.stacked ? 0 : 25,
            style: {
              fontSize: "12px",
              colors: props.stacked ? ["#FFF"] : ["#793c3c"],
            },
          },

          stroke: {
            curve: "smooth",
          },

          title: {
            text: `${props.title}  `,
            align: "center",
            style: {
              fontSize: "24px",
              fontWeight: "bold",
              color: "#263238",
              fontFamily: "Arial, sans-serif",
            },
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], //  takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

          yaxis: {
            ...prevOptions.options.yaxis,
            min: 0,

            tickAmount: 5, // This will create steps of 10 (50 / 5 = 10)
            labels: {
              formatter: (value) => value.toFixed(0), // Remove decimals
            },

            max: newNumber,
          },

          xaxis: {
            ...prevOptions.options,
            categories: props.maxCategoryLength
              ? shortenedCategories
              : props.categories,
          },

          legend: {
            position: "bottom", // Position at the bottom
            horizontalAlign: "left", // Align to the left
            offsetY: 10, // Adjust the space between chart and legend
            offsetX: 410, // Adjust the space between chart and legend
          },

          tooltip: {
            x: {
              formatter: function (value, { dataPointIndex }) {
                return props.categories[dataPointIndex]; // Show full text on hover
              },
            },
            y: {
              formatter: function (value, { dataPointIndex }) {
                return props.percentage ? value + "%" : value; // Show full text on hover
              },
            },
          },
        },
      }));
  }, [shortenedCategories]);
  //-----------------------------------------------------------
  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,

      options: {
        ...prevOptions.options,
        dataLabels: {
          enabled: false,
          formatter: (val) => `${props.percentage ? val + "%" : val} `,
        },
      },
    }));
  }, [props.percentage]);
  //-----------------------------------------------------------
  useEffect(() => {
    setLoading(false);
  }, [chartOptions]);
  //-----------------------------------------------------------

  return (
    <div
      className={`${styles.graphContainer} ${
        props.fullWidthContainer ? styles.FullGraphContainer : ""
      }`}
    >
      {props.tabs && (
        <div className={`${styles.tabsContainer} row`}>
          {props.tabs.map((tab, i) => {
            return (
              <div
                key={tab.title}
                className={`${styles.tab} ${tab.active ? styles.active : ""} `}
                style={{ width: tab.width }}
                onClick={() => props.setActiveTab(props.tabs, tab.title)}
              >
                <span className={`${tab.active ? styles.active : ""}`}>
                  {tab.title}
                  {tab.hasError && (
                    <QueryError
                      queryStructures={props.queryStructures[i]}
                      size="md"
                    />
                  )}
                </span>
                {props.withQuery && (
                  <img
                    src={EditQuery}
                    onClick={() => props.setAskQueryModal(true)}
                    className={`${styles["EditQuery"]}`}
                    alt="EditQuery"
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      <div
        className={`${styles.container} ${props.tabs ? styles.tabbed : ""}`}
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        {props.withQuery && !props.tabs && (
          <>
            <img
              src={EditQuery}
              onClick={() => props.setAskQueryModal(true)}
              className={`${styles["EditQuery"]}`}
              alt="EditQuery"
            />
          </>
        )}

        {loading ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <Rings color="#05BC86" height={550} width={550} />{" "}
          </div>
        ) : props.Gtype === "flag" &&
          props.categories &&
          props.categories.length ? (
          <FlagGraph
            series={props.series}
            categories={props.categories}
            height={props.height}
          />
        ) : props.Gtype === "pie" &&
          props.categories &&
          props.categories.length ? (
          <PieGraph
            groupByColumn={props.groupByColumn}
            series={props.series}
            categories={props.categories}
            height={props.height}
            selectedFilters={props.selectedFilters}
          />
        ) : props.Gtype === "bar" &&
          props.categories &&
          props.categories.length ? (
          <ReactApexChart
            options={chartOptions.options}
            series={props.stacked ? chartOptions.series : chartOptions.series}
            type={props.Gtype}
            height={props.height}
            width={
              props.categories && props.scroll && props.categories.length > 7
                ? props.categories.length * 15 + "%"
                : "100%"
            }
          />
        ) : (
          <h3
            style={{
              height: props.height,
              textAlign: "center",
              paddingTop: "20%",
            }}
          >
            No Graph Result
          </h3>
        )}

        {askQueryModal ? (
          <BarModal
            setAskQueryModal={setAskQueryModal}
            selectedSlice={selectedSlice}
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            stacked={props.stacked}
            percentage={props.percentage}
            colors={props.colors}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ApexChartComponent;
