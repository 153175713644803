import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { GetAPIList, GetAPIById } from "../../functions/GetAPIList";
import { extractStringsBetweenBrackets } from "../../functions/FunctionsStore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";

function Blackboard(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  const [reportsList, setReportsList] = useState([]);
  const initialQueryStructure = props.defaultQueryStructure;

  const handleChange = (event) => {
    let obj = { ...props.query };
    obj[event.target.name] = event.target.value;
    props.setQuery(obj);
  };

  const handleClose = () => props.setAskQueryModal(false);
  //---------------------------------------------------  (1)
  useEffect(() => {
    const getReportsList = async () => {
      try {
        const data = await GetAPIList("reports", "");
        setReportsList(data);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    getReportsList();

    //Set Default Query
    let obj = { ...props.query };
    if (!obj["query"]) {
      obj["query"] = initialQueryStructure;
    }
    if (!obj["reportId"]) {
      obj["reportId"] = 0;
    }
    if (!obj["queryName"]) {
      obj["queryName"] = "";
    }
    props.setQuery(obj);
  }, []);

  //---------------------------------------------------  (2)
  useEffect(() => {}, [props.query]);
  //---------------------------------------------------
  const runQuery = () => {
    if (props.handleSave()) {
      handleClose();
    }
  };
  //---------------------------------------------------

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>Edit Chart </h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Title</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="Enter Title"
                name="queryName"
                value={props.query && props.query.queryName}
                onChange={handleChange}
              />
            </div>

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Report </label>
              <Form.Select
                className="input-bordered field-input mb-4 "
                aria-label="Default select example "
                onChange={handleChange}
                name="reportId"
                value={props.query && props.query.reportId}
              >
                <option value={0}>Select Report</option>
                {reportsList &&
                  reportsList.map((report) => {
                    return (
                      <option key={report.Id} value={report.Id}>
                        {report.Name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Query</label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter your query here"
                className="input-bordered p-3 py-2   mb-4"
                name="query"
                value={props.query && props.query.query}
                onChange={handleChange}
              />
            </div>

            <Button
              className="main-button w-100 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => runQuery()}
            >
              Run Query
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Blackboard;
