import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import styles from "../Charts/Chart.module.scss";

import QueryModal from "../../DashboardElements/QueryModal";
import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { format } from "date-fns";
import Chart from "../Charts/Chart";
import AllSeriesTitle from "./AllSeriesTitle.json";
import GradeIcon from "../../../assets/svg/schoolIcon.svg";
import Filter from "../../DashboardElements/Filter";
import { Rings } from "react-loader-spinner";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Graph = ({
  data,
  viewMultiQueryResult,
  height,
  editMode,
  reloadDashboard,
  tabIndex,
}) => {
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [query, setQuery] = useState();
  const [charts, setCharts] = useState([]);

  const [seriesTitles, setSeriesTitles] = useState(AllSeriesTitle.Students);
  const [queriesIds, setQueriesIds] = useState([340, 341, 342]);

  useEffect(() => {
    setSeriesTitles(
      tabIndex === 340
        ? AllSeriesTitle.Students
        : tabIndex === 350
        ? AllSeriesTitle.Educators
        : AllSeriesTitle.Training
    );

    setQueriesIds(
      tabIndex === 340
        ? [340, 341, 342]
        : tabIndex === 350
        ? [350, 351, 352, 353, 354, 355, 356]
        : [370, 371, 372, 373]
    );
  }, [tabIndex]);

  useEffect(() => {}, [queriesIds]);

  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [queryIndex, setQueryIndex] = useState(tabIndex);
  //----------------------------------------------------

  //----------------------------------------------------
  const handleSave = () => {
    let Query = {
      queryId: queryIndex,
      queryType: "report",
      widgetType: getObjByIndex(seriesTitles, queryIndex).widgetType,
      queryName: query.queryName,
      queryText: query.query,
      reportId: query.reportId,
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
    };
    // console.log(Query);
    if (query.queryName && query.query && query.reportId) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewMultiQueryResult(queriesIds);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {});
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  //---------------------------------------------------

  const calculateSum = (arr) => {
    let total = 0;
    arr.forEach((element) => {
      total += element;
    });
    return total;
  };

  //----------------------------------------------

  useEffect(() => {
    viewMultiQueryResult(queriesIds);
  }, [reloadDashboard, queriesIds]);
  //----------------------------------------------
  const getArrayByIndex = (array, i) => {
    const item = array.find((element) => element.queryId === i);
    return item ? item.content : "no";
  };
  //******************** */
  const getObjByIndex = (array, i) => {
    const item = array.find((element) => element.queryId === i);
    return item ? item : "noObject";
  };
  //******************** */

  useEffect(() => {
    // console.log(queryIndex);
  }, [queryIndex]);
  //----------------------------------------------

  useEffect(() => {
    let arrayOfCharts = [];

    for (let index = 0; index < data.length; index++) {
      const newObj = data[index];
      const newData = data[index].content;
      let labels = getObjByIndex(seriesTitles, newObj.queryId);
      if (newData !== "no" && newData !== 0 && labels !== "noObject") {
        let val = [];
        let cat = [];
        let arrayOfSeries = [];
        for (let i = 0; i < newData.length; i++) {
          const element = newData[i];
          cat.push(
            `${getObjByIndex(seriesTitles, newObj.queryId).pre} ${
              element["xAxis"]
            } ${getObjByIndex(seriesTitles, newObj.queryId).suf}`
          );
        }

        for (let j = 0; j < labels.titles.length; j++) {
          const eachTitle = labels.titles[j];
          let ser = [];
          for (let i = 0; i < newData.length; i++) {
            const element = newData[i];
            ser.push(element["yAxis"][j]);
            val.push(calculateSum([...element.yAxis]));
          }
          arrayOfSeries.push({
            name: eachTitle,
            data: ser,
          });
        }

        arrayOfCharts.push({
          queryId: newObj.queryId,
          categories: cat,
          series: arrayOfSeries,
          max: Math.max(...val),
        });
      } else {
        arrayOfCharts.push({
          queryId: newObj.queryId,
          categories: [],
          series: [],
          max: 100,
        });
      }
    }

    setCharts(arrayOfCharts);
  }, [data]);

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedGraphQuery();
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === queryIndex) {
            // console.log(element);
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          } else {
            setQuery({
              queryName: "",
              reportId: 0,
              query: getObjByIndex(seriesTitles, queryIndex).queryStructure,
            });
          }
        }
      } catch (error) {
        console.error("Error000 :", error);
      }
    };
    SavedGraphQuery();
  }, [queryIndex]);
  //---------------------------------------------------

  return (
    <>
      <div className={`${styles.ChartsContainer} row`}>
        <div className={`${styles.leftGradeFilter} `}></div>
        <div className={`${styles.gradeFilter} `}>
          {" "}
          <Filter
            icon={GradeIcon}
            placeholder={"All Grades"}
            container_class={"Date"}
            options={[]}
            blue
            selected={""}
          />
        </div>

        {/* {charts ?  <Rings color="#05BC86" height={400} width={600} />   :null} */}

        {charts &&
          charts.map((chart) => {
            return (
              <Chart
                key={chart.queryId}
                queryId={chart.queryId}
                withQuery={editMode}
                setAskQueryModal={setAskQueryModal}
                height={height}
                title={getObjByIndex(seriesTitles, chart.queryId).title}
                subTitle={getObjByIndex(seriesTitles, chart.queryId).subTitle}
                Gtype={getObjByIndex(seriesTitles, chart.queryId).type}
                stacked={getObjByIndex(seriesTitles, chart.queryId).stacked}
                showtoolbar={true}
                colors={getObjByIndex(seriesTitles, chart.queryId).colors}
                horizontal={
                  getObjByIndex(seriesTitles, chart.queryId).horizontal
                }
                series={chart.series}
                categories={chart.categories}
                handleSave={handleSave}
                max={chart.max}
                tabIndex={tabIndex}
                setQueryIndex={setQueryIndex}
              />
            );
          })}
      </div>

      {askQueryModal ? (
        <QueryModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          handleSave={handleSave}
          setCategories={setCategories}
          setSeries={setSeries}
          categories={categories}
          series={series}
        />
      ) : null}
    </>
  );
};

export default Graph;
