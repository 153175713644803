import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { style } from "@mui/system";
import styles from "./barGraph.module.scss";
import { Flex } from "antd";

const Flags = ({ series, categories, height }) => {
  // Labels for the pie chart
  const [flags, setFlags] = useState([]);
  const [flagSvg, setFlagSvg] = useState([]);
  const [results, setResults] = useState([]);

  //----------------------------------------------
  useEffect(() => {
    // results && results.length && console.log(results);
  }, [results]);
  //----------------------------------------------
  useEffect(() => {
    // console.log(flagSvg);
    // console.log(flagSvg);

    let arr1 = [...flagSvg];
    let arr = [];

    for (let i = 0; i < arr1.length; i++) {
      arr.push({
        CountryName: arr1[i].name,
        CountryCount:
          series &&
          series.length &&
          [...series][0].data[[...categories].indexOf(arr1[i].name)]
            ? [...series][0].data[[...categories].indexOf(arr1[i].name)]
            : 0,
        CountryFlag: arr1[i].flag,
      });
    }
    setResults(arr.sort((a, b) => b.CountryCount - a.CountryCount));
  }, [flagSvg]);

  //-------------------------------------------------------
  const fetchAllFlags = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const flagData = response.data.map((country) => ({
        name: country.name.common,
        flag: country.flags.svg,
      }));
      setFlagSvg(flagData);
    } catch (err) {
      setFlagSvg([]);
    }
  };

  //------------------------

  useEffect(() => {
    let arr1 = series && series.length ? [...series][0].data : [];
    let arr2 = [...categories];
    let arr = [];

    for (let i = 0; i < arr1.length; i++) {
      const element = arr1[i];
      arr.push({
        CountryName: arr2[i],
        CountryCount: element,
      });
    }
    setFlags(arr);

    fetchAllFlags();

    // setResults([...series].sort((a, b) => b - a));
  }, [series]);
  //------------------------------------------------------------------
  return (
    <div
      className="row"
      style={{ height: height, paddingTop: "5%", display: "Flex", gap: "5%" }}
    >
      {/* {flagSvg &&
        flagSvg.map((flag, i) => {
          return [...categories].indexOf(flag.name) > -1 ? (
            <div className={`${styles.flagContainer}`} key={flag.name}>
              {" "}
              <img src={flag.flag} />
              <h5>{flag.name}</h5>
              <h6>
                {results && results.length
                  ? [...results][0].data[[...categories].indexOf(flag.name)]
                  : 0}
              </h6>
            </div>
          ) : null;
        })} */}

      {results &&
        results.map((flag, i) => {
          return [...categories].indexOf(flag.CountryName) > -1 ? (
            <div className={`${styles.flagContainer}`} key={flag.CountryName}>
              {" "}
              <img src={flag.CountryFlag} />
              <h5>{flag.CountryName}</h5>
              <h6>{flag.CountryCount}</h6>
            </div>
          ) : null;
        })}
    </div>
  );
};

export default Flags;
