import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import GetBaseUrl from "../../functions/SetBaseUrl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";
import axios from "axios";
import Table from "../../components/Molecules/basicTable";
import styles from "./DashboardStatics.module.scss";
import { GetAPISavedGraphQuery } from "../../functions/GetAPIList";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
function PieModal(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  const [rows, setRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [query, setQuery] = useState();

  const handleClose = () => props.setAskQueryModal(false);

  useEffect(() => {
    setLoadingTable(true);
    // getSelectedPieResult();
  }, [props.selectedSlice]);
  //---------------------------------------------------

  useEffect(() => {
    props.selectedSlice &&
      props.groupByColumn &&
      getSelectedPieResult({
        columnName: props.groupByColumn,
        filterValue: props.selectedSlice,
      });
  }, [props.groupByColumn]);

  //---------------------------------------------------getGraphResult
  const getSelectedPieResult = async (object) => {
    let obj = {
      queryId: 25,
      Period: 0,
      filterData: props.selectedFilters,
      optionalFilterData: [object],
    };

    try {
      const response = await axios.post(
        BaseUrl + "/Report/GetDashboardReportQueryData",
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      // console.log(response.data.rows);
      setRows(response.data.rows);
      setLoadingTable(false);
    } catch (error) {}
  };

  //---------------------------------------------------
  const headerArray = [
    {
      name: "City",
      originalName: "college_city_api",
      visible: true,
      width: "50%",
    },
    {
      name: "Total",
      originalName: "Count",
      visible: true,
      width: "25%",
    },
    {
      name: "Percentage",
      originalName: "Percentage",
      visible: true,
      width: "25%",
    },
  ];

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>{props.selectedSlice}</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div
              className={` ${styles.tableContainer}  ${
                props.askQueryModal ? styles.show : ""
              } `}
            >
              <Table
                loadingTable={loadingTable}
                tableHeader={headerArray}
                data={rows}
                selectedColumn="college_city_api"
                setSelectedColumn={setSelectedColumn}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PieModal;
