import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardStatics.module.scss";
import Card1 from "../../assets/svg/Dashboard/AUD/Card1.svg";
import Card2 from "../../assets/svg/Dashboard/AUD/Card2.svg";
import Card3 from "../../assets/svg/Dashboard/AUD/Card3.svg";
import Card4 from "../../assets/svg/Dashboard/AUD/Card4.svg";
import Card5 from "../../assets/svg/Dashboard/AUD/Card5.svg";
import EditQuery from "../../assets/images/EditQuery.svg";
import QueryStaticsModal from "./QueryStaticsModal";
import { GetAPISavedStaticsQuery } from "../../functions/GetAPIList";
import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";
import nextIcon from "../../assets/images/AUD_Dashboard/nextIcon.gif";
import noteIcon from "../../assets/images/AUD_Dashboard/noteIcon.svg";
import { height } from "@mui/system";
import Table from "../../components/table/customTable";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const DashboardStatics = (props) => {
  const headerArray = [
    {
      name: "Full Name",
      originalName: "studentname",
      visible: true,
      width: "30%",
    },
    {
      name: "Program",
      originalName: "programdescrip",
      visible: true,
      width: "25%",
    },
    {
      name: "agency",
      originalName: "agency",
      visible: true,
      width: "15%",
    },
    {
      name: "School",
      originalName: "high_school_api",
      visible: true,
      width: "20%",
    },
    {
      name: "Status",
      originalName: "my_status",
      visible: true,
      width: "20%",
    },
  ];

  //-----------------------------------
  const [internalFilter, setInternalFilter] = useState([]);

  const [askQueryModal, setAskQueryModal] = useState(false);
  const [queryId, setQueryId] = useState();
  const [reloadStatistics, setReloadStatistics] = useState(true);
  const [selectedStatics, setSelectedStatics] = useState([]);

  const [selected, setSelected] = useState();
  const [query, setQuery] = useState();
  const [exportOption, setExportOption] = useState("");

  const [loadingTable, setLoadingTable] = useState(0);
  const [tableName, setTableName] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [activePeriod, setActivePeriod] = useState(0);
  const [columns, setColumns] = useState([]);
  const [selectedDateColumn, setSelectedDateColumn] = useState("");
  const handleChangeDateColumn = (event) => {
    setSelectedDateColumn(event.target.value);
  };
  const [statistics, setStatistics] = useState([
    {
      index: 11,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card1,
      title: "Leads",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 12,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card2,
      title: "Applicants",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 13,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card3,
      title: "No Show",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 14,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card4,
      title: "Rejected",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 15,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card5,
      title: "Approved",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 16,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card3,
      title: "No Show",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 17,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card4,
      title: "Rejected",
      defaultQueryStructure: `Select 
   Count`,
    },
    {
      index: 18,
      reportId: 0,
      value: 0,
      active: false,
      icon: Card5,
      title: "Approved",
      defaultQueryStructure: `Select 
   Count`,
    },
  ]);

  //------------------------------
  const viewQueryResult = (index) => {
    let obj = {
      queryId: index,
      Period: activePeriod,
      filterData: props.selectedFilters,
      comparisonFilterData: props.selectedComparisonFilters,
    };
    // console.log(obj);
    let updatedArray = [...statistics];

    obj.comparisonFilterData &&
      !obj.comparisonFilterData.length &&
      delete obj.comparisonFilterData;

    if (obj.filterData.length) {
      axios
        .post(BaseUrl + "/Report/GetDashboardReportQueryData", obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data && res.data.queryName) {
            for (var i = 0; i < updatedArray.length; i++) {
              if (updatedArray[i].index === index) {
                updatedArray[i].value = res.data.filterTotal;
                updatedArray[i].percentage =
                  res.data.percentageFilter && res.data.percentageFilter;

                updatedArray[i].comparisonValue = res.data.comparisonTotal;
                updatedArray[i].comparisonPercentage =
                  res.data.percentageComparison &&
                  res.data.percentageComparison;

                updatedArray[i].reportId = res.data.reportid ?? 0;

                updatedArray[i].title = res.data.queryName;
                break;
              }
            }
          }
        })
        .catch((res) => {
          for (var i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].index === index) {
              updatedArray[i].value = 0;
              updatedArray[i].percentage = 0;
              updatedArray[i].comparisonValue = 0;
              updatedArray[i].percentageComparison = 0;
              updatedArray[i].reportId = 0;
              updatedArray[i].title = "N/A";
              break;
            }
          }
        })
        .finally(() => {
          setStatistics(updatedArray);
        });
    }
  };

  //---------------------
  useEffect(() => {
    for (let i = 0; i < statistics.length; i++) {
      const element = statistics[i].index;
      viewQueryResult(element);
    }
  }, [props.selectedFilters, props.selectedComparisonFilters]);
  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery("AUD");
        // console.log(data);
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === queryId) {
            setSelectedDateColumn(element.dateColumnId);
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          } else {
            setQuery({
              queryName: "",
              reportId: 0,
              query: selectedCard && selectedCard.defaultQueryStructure,
            });
          }
        }
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();
  }, [queryId]);

  //----------------------------------------------
  useEffect(() => {
    const SavedStaticsQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery();
        setSelectedStatics(data);
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };
    if (reloadStatistics) {
      SavedStaticsQuery();
    }
  }, [reloadStatistics]);
  //----------------------------------------------
  const formatNumber = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return num;
    }
  };
  //---------------------------------------------------
  const selectCard = (index, newValue) => {
    const updatedData = statistics.map((item) =>
      item.index === index
        ? { ...item, active: newValue }
        : { ...item, active: false }
    );
    setStatistics(updatedData);
    newValue ? setLoadingTable(index) : setLoadingTable(0);

    setIsVisible(newValue);
  };
  //-----------------------------------

  return (
    <>
      <div className={`${styles.container} row`}>
        {statistics &&
          statistics.map((item, index) => {
            return (
              <>
                <div
                  key={item.index}
                  className={`${styles.cardContainer} `}
                  onClick={() => {
                    selectCard(item.index, !item.active);
                    setTableName(item.title + " List");
                    setSelectedCard(item);
                    setQueryId(item.index);
                    setInternalFilter([]);
                  }}
                >
                  <div
                    className={`${styles.card} ${
                      item.active ? styles.active : ""
                    } row`}
                  >
                    {props.withQuery && (
                      <img
                        src={EditQuery}
                        onClick={() => {
                          setSelected(index);
                          setAskQueryModal(true);
                          setQueryId(item.index);
                        }}
                        className={`${styles["EditQuery"]}`}
                        alt="EditQuery"
                      />
                    )}
                    <div className={` row`}>
                      <label className={`${styles.child} `}>
                        {item.title ?? "N/A"}
                      </label>
                      <img src={item.icon} />
                    </div>
                    {props.comparisonMode ? (
                      <div className={`${styles.comparison} row`}>
                        <label className={`${styles.child} `}>semester1</label>
                        <h2 className={`${styles.child} `}>
                          {item.value ? formatNumber(item.value) : "0"}
                        </h2>
                        <h4 className={`${styles.child} `}>
                          {item.percentage
                            ? formatNumber(item.percentage) + "%"
                            : "0%"}
                        </h4>

                        <label
                          className={`${styles.comparison} ${styles.child} `}
                        >
                          semester2
                        </label>
                        <h2 className={`${styles.comparison} ${styles.child} `}>
                          {item.comparisonValue
                            ? formatNumber(item.comparisonValue)
                            : "0"}
                        </h2>
                        <h4 className={`${styles.comparison} ${styles.child} `}>
                          {item.comparisonPercentage
                            ? formatNumber(item.comparisonPercentage) + "%"
                            : "0%"}
                        </h4>
                      </div>
                    ) : null}
                    {!props.comparisonMode && (
                      <>
                        <h2 className={`${styles.child} `}>
                          {item.value ? formatNumber(item.value) : "0"}
                        </h2>
                        <h4 className={`${styles.child} `}>
                          {item.percentage
                            ? formatNumber(item.percentage) + "%"
                            : "0%"}
                        </h4>
                      </>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        {/* ${styles.component} ${isVisible ? styles.show : ""} */}
        <div
          className={` ${styles.tableContainer}  ${
            isVisible ? styles.show : ""
          } `}
        >
          <Table
            setExportOption={setExportOption}
            exportOption={exportOption}
            queryId={queryId}
            loadingTable={loadingTable}
            tableName={tableName}
            headerArray={headerArray}
            ActionColumn={"Step Status"}
            columnsVisibility
            columnsFilters
            selectedCard={selectedCard}
            setStatistics={setStatistics}
            statistics={statistics}
            selectedFilters={props.selectedFilters}
            setSelectedFilters={props.setSelectedFilters}
            askQueryModal={askQueryModal}
            internalFilter={internalFilter}
            setInternalFilter={setInternalFilter}
          />
        </div>
        {askQueryModal ? (
          <QueryStaticsModal
            setAskQueryModal={setAskQueryModal}
            query={query}
            setQuery={setQuery}
            queryId={queryId}
            setReloadDashboard={props.setReloadDashboard}
            viewQueryResult={viewQueryResult}
            setStatistics={setStatistics}
            statistics={statistics}
            selectedCard={selectedCard}
          />
        ) : null}
      </div>
    </>
  );
};

export default DashboardStatics;
