import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardHeader.module.scss";
import Filter from "./Filter";
import ToggleOptions from "./ToggleOptions";
import QueryFiltersModal from "./QueryFiltersModal";
import FiltersModal from "./FiltersModal";
import {
  GetAPIFilterData,
  GetAPISavedFilterQuery,
} from "../../functions/GetAPIList";
import WeekIcon from "../../assets/svg/weekIcon.svg";

const DashboardHeader = (props) => {
  const [Semester, setSemester] = useState([]);
  const [Semester2, setSemester2] = useState([]);
  const [FilterIndex, setFilterIndex] = useState(1);
  const [queryId, setQueryId] = useState();
  const [Filters, setFilters] = useState([]);
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [FilterCol, setFilterCol] = useState("");
  const [filtersModal, setFiltersModal] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState();
  const [selectedFilter2, setSelectedFilter2] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);

  //----------------------------------------------
  useEffect(() => {}, [selectedFilter]);

  //--------------------------------------
  useEffect(() => {
    setFilters([
      {
        container_class: "Date",
        icon: WeekIcon,
        options: Semester,
        placeholder: "Select",
        selected: selectedFilter,
        onChange: setSelectedFilter,
        comparison: false,
        blue: true,
      },
      {
        container_class: "Comparison",
        icon: WeekIcon,
        options: Semester2,
        placeholder: "Select",
        selected: selectedFilter2,
        onChange: setSelectedFilter2,
        comparison: true,

        blue: true,
      },
    ]);
  }, [Semester, Semester2]);

  useEffect(() => {
    const Fill_Filters = async (Id, Index) => {
      try {
        const data = await GetAPIFilterData(Id);
        console.log(data);
        const data1 = [
          {
            value: "0",
            label: "Select",
            columnId: "00000000-0000-0000-0000-000000000000",
          },
          ...data,
        ];

        if (Index === 1) {
          setSemester(data1);
          setSemester2(data1);
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    const SavedFilterQuery = async () => {
      try {
        const data = await GetAPISavedFilterQuery("AUD");
        // console.log(data);
        setSelectedFilters(data);
        for (let i = 0; i < data.length; i++) {
          const columnId = data[i].columnId;
          columnId && Fill_Filters(columnId, i + 1);
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    SavedFilterQuery();
  }, []);

  //---------------------------------------------------  (1)
  const ApplyFilters = () => {
    props.setReloadDashboard(Math.random(400));
  };

  //----------------------------------------------

  useEffect(() => {
    const Fill_Filters = async (Id, Index) => {
      try {
        const data = await GetAPIFilterData(Id);
        // console.log(data);

        if (Index === 1) {
          setSemester(data);
          setSemester2(data);
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    FilterCol && Fill_Filters(FilterCol, FilterIndex);
  }, [FilterCol]);

  //----------------------------------------------

  return (
    <div className={`${styles.container} row `}>
      <label className={`${styles.label} `}>AUD Dashboard</label>

      <div className={`${styles["Filter-container"]} row `}>
        {Filters &&
          Filters.map((item, index) => {
            return !item.blue ||
              (item.comparison && !props.comparisonMode) ? null : (
              <Filter
                key={index}
                index={index + 1}
                setQueryId={setQueryId}
                icon={item.icon}
                comparison={item.comparison}
                placeholder={item.placeholder}
                container_class={item.container_class}
                options={item.options}
                blue
                withQuery={props.withQuery}
                setAskQueryModal={setAskQueryModal}
                selected={item.selected}
                onChange={item.onChange}
                setFilterIndex={setFilterIndex}
                selectedFilters={props.selectedFilters}
                setSelectedFilters={props.setSelectedFilters}
                selectedComparisonFilters={
                  props.selectedComparisonFilters &&
                  props.selectedComparisonFilters
                }
                setSelectedComparisonFilters={
                  props.setSelectedComparisonFilters
                }
              />
            );
          })}
        <ToggleOptions
          setEditMode={props.setEditMode}
          editMode={props.editMode}
          setComparisonMode={props.setComparisonMode}
          comparisonMode={props.comparisonMode}
          container_class="Options"
        />

        {askQueryModal ? (
          <QueryFiltersModal
            setAskQueryModal={setAskQueryModal}
            setFilterCol={setFilterCol}
            queryId={queryId}
            selectedFilters={selectedFilters}
          />
        ) : null}

        {filtersModal ? (
          <FiltersModal
            Filters={Filters}
            ApplyFilters={ApplyFilters}
            setFiltersModal={setFiltersModal}
            setQueryId={setQueryId}
            withQuery={props.withQuery}
            setAskQueryModal={setAskQueryModal}
            setFilterIndex={setFilterIndex}
            selectedFilters={props.selectedFilters}
            setSelectedFilters={props.setSelectedFilters}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DashboardHeader;
