import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import GetBaseUrl from "../../functions/SetBaseUrl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";
import axios from "axios";
import Table from "../../components/Molecules/basicTable";
import styles from "./DashboardStatics.module.scss";
import { GetAPISavedGraphQuery } from "../../functions/GetAPIList";
import Chart from "react-apexcharts";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
function BarModal(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  const [rows, setRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [query, setQuery] = useState();

  const handleClose = () => {
    props.setAskQueryModal(false);
    props.setSelectedData(null);
  };

  useEffect(() => {
    setLoadingTable(true);
    // getSelectedPieResult();
  }, [props.selectedSlice]);

  useEffect(() => {
    console.log(props.selectedData);
  }, [props.selectedData]);
  //---------------------------------------------------

  //   useEffect(() => {
  //     props.selectedSlice &&
  //       props.groupByColumn &&
  //       getSelectedPieResult({
  //         columnName: props.groupByColumn,
  //         filterValue: props.selectedSlice,
  //       });
  //   }, [props.groupByColumn]);

  //---------------------------------------------------getGraphResult
  //   const getSelectedPieResult = async (object) => {
  //     let obj = {
  //       queryId: 25,
  //       Period: 0,
  //       filterData: props.selectedFilters,
  //       optionalFilterData: [object],
  //     };

  //     try {
  //       const response = await axios.post(
  //         BaseUrl + "/Report/GetDashboardReportQueryData",
  //         obj,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "content-type": "application/json",
  //           },
  //         }
  //       );
  //       // console.log(response.data.rows);
  //       setRows(response.data.rows);
  //       setLoadingTable(false);
  //     } catch (error) {}
  //   };

  //---------------------------------------------------
  const options = {
    chart: {
      type: "bar",
    },

    yaxis: {
      min: 0,

      //   tickAmount: 5, // This will create steps of 10 (50 / 5 = 10)
      labels: {
        formatter: (value) => value.toFixed(0), // Remove decimals
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: props.stacked ? "35%" : "15%", // Adjusts the thickness of the bars
        borderRadius: 8, // Fully rounded bars
      },
    },

    colors: props.colors, // Custom colors for each series

    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        // Calculate percentage relative to the total value of the series
        const total = opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        const per = props.percentage ? val + "%" : val;
        return per; // Show percentage on bars
      },
      //   offsetY: props.stacked ? 0 : 25,
      style: {
        fontSize: "12px",
        colors: props.stacked ? ["#141814"] : ["#FFF"],
      },
    },

    // tooltip: {
    //   x: {
    //     formatter: function (value, { dataPointIndex }) {
    //       return props.categories[dataPointIndex]; // Show full text on hover
    //     },
    //   },
    //   y: {
    //     formatter: function (value, { dataPointIndex }) {
    //       return props.percentage ? value + "%" : value; // Show full text on hover
    //     },
    //   },
    // },

    xaxis: {
      categories: [props.selectedSlice],
    },
  };

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>{props.selectedSlice}</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div
              className={` ${styles.tableContainer}  ${
                props.askQueryModal ? styles.show : ""
              } `}
            >
              <Chart
                options={options}
                series={props.selectedData}
                type="bar"
                height={600}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BarModal;
