import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import { IndexContext } from "../../../components/layout/Layout";
import Table from "../../../components/Molecules/customTable";
import TableHeader from "../../../components/Molecules/TableTopHeader";
import CustomPaginator from "../../Molecules/Paginator";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const SavedOutput = ({
  loadingTable,
  tableName,
  headerArray,
  ActionColumn = false,
  columnsFilters = false,
  columnsVisibility = false,
  smallTable = false,
  showPaginator = true,
  defaultPerPage = 5,
  selectedCard,
  queryId,
  exportOption,
  setExportOption,
  askQueryModal,
  statistics,
  selectedFilters,
  setSelectedFilters,
  internalFilter,
  setInternalFilter,
}) => {
  const IndexProvider = useContext(IndexContext);
  //----------------------------------------------------- ----Index Style
  const [PageSize, setPageSize] = useState(defaultPerPage);
  const [Page, setPage] = useState(1);
  const [reload, setReload] = useState(null);
  const [List, setList] = useState([]);
  const [ExportList, setExportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [tableHeader, setTableHeader] = useState(headerArray);

  const ExportTableToPDF = () => {
    const input = document.getElementById("Applicants_Table");

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "pt",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
        pdf.save("table.pdf");
      })
      .catch((err) => {
        console.log("Error generating PDF:", err);
      });
  };
  //---------------------------------------------------------------------

  const refetchExportList = (
    Page = Page,
    PageSize = PageSize,
    GlobalFilter
  ) => {
    let obj = {
      repId: selectedCard ? selectedCard.reportId : 0,
      pageNo: Page,
      pageSize: PageSize,

      queryId: queryId,
      filters: GlobalFilter,
    };

    axios
      .post(BaseUrl + `/Report/PullReportById`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setExportList(res.data.rows ?? []);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  //---------------------------------------------------------------------
  const refetchList = (
    Page = Page,
    PageSize = PageSize,
    Export = "",
    GlobalFilter = []
  ) => {
    if (!Export) {
      setReload(false);
      setLoading(true);
    }

    if (PageSize === -1) {
      PageSize = rowsCount;
    }

    let obj = {
      repId: selectedCard ? selectedCard.reportId : 0,
      pageNo: Page,
      pageSize: PageSize,
      selectedColumn: selectedColumn,
      sortDirection: sortDirection,
      queryId: queryId,
      exportOption: Export === "xlsx" ? "Excel" : "PDF",
      filters: internalFilter
        ? [...GlobalFilter, ...internalFilter]
        : [...GlobalFilter],
    };

    !selectedColumn && delete obj.selectedColumn;
    !Export && delete obj.exportOption;

    axios
      .post(BaseUrl + `/Report/PullReportById`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (Export === "xlsx") {
            const link = document.createElement("a");
            link.href = res.data;
            document.body.appendChild(link);
            link.click();
            setExportOption("");
          } else {
            setReload(true);
            setList(res.data.rows ?? []);
            setRowsCount(res.data.totalCount);
          }
        }
      })
      .catch(() => {
        toast.error("Error Pull Report By Id", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {});
  };
  //---------------------------------------------------------------------
  useEffect(() => {
    let Filters = [];
    selectedFilters &&
      selectedFilters.map((Item) => {
        Filters.push({
          columnId: Item.columnId,
          columnName: "",
          columnValue: Item.filterValue,
        });
      });
    Filters.length && refetchList(Page, PageSize, exportOption, Filters);
    !Filters.length && refetchList(Page, PageSize, "");
  }, [Page, PageSize, selectedColumn, sortDirection]);
  //---------------------------------------------------------------------
  useEffect(() => {
    let Filters = [];
    selectedFilters &&
      selectedFilters.map((Item) => {
        Filters.push({
          columnId: Item.columnId,
          columnName: "",
          columnValue: Item.filterValue,
        });
      });

    loadingTable && refetchList(1, defaultPerPage, exportOption, Filters);
  }, [loadingTable]);
  //---------------------------------------------------------------------
  useEffect(() => {
    setLoading(false);
  }, [List]);

  useEffect(() => {
    setLoading(true);
  }, []);
  //---------------------------------------------------------------------

  useEffect(() => {
    let Filters = [];
    selectedFilters &&
      selectedFilters.map((Item) => {
        Filters.push({
          columnId: Item.columnId,
          columnName: "",
          columnValue: Item.filterValue,
        });
      });
    exportOption === "xlsx" &&
      refetchList(1, defaultPerPage, exportOption, Filters);

    if (exportOption === "pdf") {
      refetchExportList(1, 0, Filters);
    }
  }, [exportOption]);
  //---------------------

  useEffect(() => {
    if (ExportList && ExportList.length) {
      setTimeout(() => {
        ExportTableToPDF();
        setExportOption("");
      }, 5000);
    }
  }, [ExportList]);

  //---------------------------------

  useEffect(() => {
    let Filters = [];
    selectedFilters &&
      selectedFilters.map((Item) => {
        Filters.push({
          columnId: Item.columnId,
          columnName: "",
          columnValue: Item.filterValue,
        });
      });

    Filters.length && refetchList(1, defaultPerPage, exportOption, Filters);
  }, [selectedFilters, internalFilter]);

  //---------------------------------------------------------------------

  return (
    <div className=" ">
      <Table
        setExportOption={setExportOption}
        selectedColumn={selectedColumn}
        sortDirection={sortDirection}
        setSelectedColumn={setSelectedColumn}
        setSortDirection={setSortDirection}
        limit={PageSize}
        tablePage={Page - 1}
        setTablePageSize={setPageSize}
        setTablePage={setPage}
        totalRows={rowsCount}
        normalTable
        normalTableName={tableName}
        data={List}
        ExportList={ExportList}
        loading={loading}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        headerBtns={[]}
        lastColumn={"my_status"}
        withActionColumn={ActionColumn}
        rowsCount={rowsCount}
        columnsVisibility={columnsVisibility}
        columnsFilters={columnsFilters}
        smallTable={smallTable}
        showPaginator={showPaginator}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        internalFilter={internalFilter}
        setInternalFilter={setInternalFilter}
        queryId={queryId}
      />

      {showPaginator && (
        <CustomPaginator
          totalItems={rowsCount}
          setTablePageSize={setPageSize}
          setTablePage={setPage}
          perPage={PageSize}
          page={Page}
          queryId={queryId}
          defaultPerPage={defaultPerPage}
        />
      )}
    </div>
  );
};

export default SavedOutput;
