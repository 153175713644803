const saveSession = (
  Server_type,
  Auth_FullName,
  Auth_Token,
  Auth_TokenExpiry,
  CompanyId,
  Role,
  dashboardsPermissions,
  username
) => {
  localStorage.setItem(Server_type + "_Edu_Fullname", Auth_FullName);
  localStorage.setItem(Server_type + "_Edu_AuthToken", Auth_Token);
  localStorage.setItem(Server_type + "_Edu_AuthTokenExpiry", Auth_TokenExpiry);
  localStorage.setItem(Server_type + "_CompanyId", CompanyId);
  localStorage.setItem(Server_type + "_Role", Role);
  localStorage.setItem(
    Server_type + "_dashboardsPermissions",
    dashboardsPermissions
  );
  localStorage.setItem(Server_type + "_username", username);
};

export default saveSession;
