import { width } from "@mui/system";
import React, { useEffect, useState, useRef, useContext } from "react";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import styles from "./ActionBtn.module.scss";
import { IndexContext } from "../layout/Layout";

// Define the InputText component
const InputText = ({
  name,
  placeholder,
  type = "text",
  setSelectedFilters,
  selectedFilters,
  internalFilter,
  setInternalFilter,
  queryId,
}) => {
  const [text, setText] = useState("");
  const getObjByIndex = (array, i) => {
    const item = array.find(
      (element) => element.columnName === i || element.columnId === i
    );
    return item ? item : "noObject";
  };

  const handleChange = (e) => {
    setText(e.target.value);
    let array = [...internalFilter];
    let check = getObjByIndex(array, e.target.name);
    let found = false;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (check.columnName === element.columnName) {
        array.splice(i, 1);
        found = true;
        break;
      }
    }

    // found &&
    array.push({
      columnName: e.target.name,
      columnValue: e.target.value,
    });
    setInternalFilter(array);
  };

  //-------------------------------------------

  useEffect(() => {
    setInternalFilter([]);
    setText("");
  }, [queryId]);

  return (
    <div style={{ margin: "15px" }}>
      <input
        type={type}
        value={text}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          padding: "10px",
          border: "1px solid #c0c0c0",
          borderRadius: "4px",
          width: "100%",
          boxSizing: "border-box",
        }}
      />
    </div>
  );
};

export default InputText;
