import React, { useEffect, useState, useContext } from "react";
import styles from "./ToggleOptions.module.scss";
import Select, { components } from "react-select";
import EmiratesIcon from "../../assets/svg/EmiratesIcon.svg";
import SchoolIcon from "../../assets/svg/schoolIcon.svg";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import more from "../../assets/svg/more.svg";
import ExportExcelIcon from "../../assets/svg/ExportExcel.svg";
import ExportPDFIcon from "../../assets/svg/ExportPDF.svg";
import PrintIcon from "../../assets/svg/Print.svg";

import GetBaseUrl from "../../functions/SetBaseUrl";

const Toggle = ({ container_class, Print, setExportOption }) => {
  var role = localStorage.getItem(GetBaseUrl()[1] + "_Role");

  return (
    <div className={`${styles[container_class]}`}>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          split
          variant=""
          id="dropdown-split-basic"
          className=""
        >
          <img src={more} alt="aa" id="dropdown-split-basic" />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${styles["DropdownContainer"]} more-drop`}>
          <>
            <Dropdown.Item
              className={`${styles["DropdownItem"]} row`}
              onClick={() => setExportOption("xlsx")}
            >
              <span>Export as Excel</span>
              <img src={ExportExcelIcon} alt="Export as Excel" />
            </Dropdown.Item>

            <Dropdown.Item
              className={`${styles["DropdownItem"]} row`}
              onClick={() => setExportOption("pdf")}
            >
              <span>Export as PDF</span>
              <img src={ExportPDFIcon} alt="Export as PDF" />
            </Dropdown.Item>

            <Dropdown.Item
              className={`${styles["DropdownItem"]} row`}
              onClick={() => Print()}
            >
              <span>Print</span>
              <img src={PrintIcon} alt="Print" />
            </Dropdown.Item>
          </>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Toggle;
