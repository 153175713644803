import { React, useEffect, useState } from "react";
import RelationshipList from "../components/relationship/RelationshipList";
import ProjectsList from "../components/relationship/ProjectList";
import Actions from "../components/relationship/Actions";
import RelationshipLayout from "../components/relationship/RelationshipLayout";
import AllTablesLinksContainer from "../components/modals/AllTablesLinksContainer";
import TableList from "../components/UploadedTable/TableList";

import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const Relationships = () => {
  const [relationshipsList, setRelationshipsList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  const [Work_Tables, setWork_Tables] = useState([]);
  const [MainJsonBody, setMainJsonBody] = useState([]);
  const [tableHead, setTableHead] = useState([]);
  const [tableHeadIds, setTableHeadIds] = useState([]);
  const [arrayToGenerate, setArrayToGenerate] = useState([]);
  const [RelationshipName, setRelationshipName] = useState("");
  const [reloadList, setReloadList] = useState(false);
  const [reload, setReload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const [showLayout, setShowLayout] = useState(true);
  const [showLinks, setShowLinks] = useState(false);
  const [TableArray, setTableArray] = useState([]);

  const [relationshipId, setRelationshipId] = useState("");
  const [newName, setNewName] = useState("");
  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const [tablesList, setTablesList] = useState([]);
  const [addTable, setAddTable] = useState();
  const [reloadLists, setReloadLists] = useState(true);

  const [DefaultEdges, setDefaultEdges] = useState([]);
  const [DefaultNodes, setDefaultNodes] = useState([]);

  useEffect(() => {
    if (showLayout) setShowLinks(false);
  }, [showLayout]);
  useEffect(() => {
    if (showLinks) setShowLayout(false);
  }, [showLinks]);

  //----------------------------------------------
  function saveNewRelationship(name) {
    let Body = [...MainJsonBody];
    const BaseUrl = GetBaseUrl()[0];
    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    let newBody = [];

    for (var j = 0; j < Body.length; j++) {
      if (Body[j].reference) {
        delete Body[j].tableColumns;
        delete Body[j].columnId;
        delete Body[j].tableName;
        delete Body[j].Sequence;
        for (var i = 0; i < Body[j].reference.length; i++) {
          delete Body[j].reference[i].Target;
          delete Body[j].reference[i].ByColumnName;
          delete Body[j].reference[i].ByColumn;
        }
        if (Body[j].reference.length !== 0) {
          newBody.push(Body[j]);
        }
      }
    }

    let obj = {
      relationShipId: relationshipId,
      relationShipName: name,
      tables: newBody,
    };

    if (!name) {
      toast.error("Relationship name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (obj.tables.length < 1) {
      toast.error("Add Tables to Relationship", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    axios
      .post(BaseUrl + "/Relations2/CreateRelationship", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setMainJsonBody([]);
        setWork_Tables([]);
        setNewName("");
      });
  }
  //----------------------------------------------

  //----------------------------------------------------
  useEffect(() => {
    console.log(MainJsonBody);
  }, [MainJsonBody]);

  //----------------------------------------------
  function removeFromRelationship(TableId) {
    let WT = [...Work_Tables];
    let MJB = [...MainJsonBody];

    let related = false;
    let abort1 = false;
    let abort2 = false;
    for (var i = 0; i < MJB.length && !abort1; i++) {
      if (
        MJB[i].tableId === TableId &&
        MJB[i].reference &&
        MJB[i].reference.length > 0
      ) {
        related = true;
        abort1 = true;
        toast.info("Remove related Links then Remove Table", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (MJB[i].reference) {
          for (var j = 0; j < MJB[i].reference.length && !abort2; j++) {
            if (
              FindObj(MJB[i].reference[j].target, "tableId", TableId) &&
              MJB[i].reference[j].target &&
              MJB[i].reference[j].target.length > 0
            ) {
              related = true;
              abort2 = true;
              abort1 = true;
              toast.info("Remove related Links then Remove Table", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (
              FindObj(MJB[i].reference[j].Target, "targetTableId", TableId) &&
              MJB[i].reference[j].Target &&
              MJB[i].reference[j].Target.length > 0
            ) {
              related = true;
              abort2 = true;
              abort1 = true;
              toast.info("Remove related Links then Remove Table", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        }
      }
    }
    if (related) {
      return false;
    }

    FindDeleteObj(WT, "TableId", TableId);
    FindDeleteObj(MJB, "tableId", TableId);

    setMainJsonBody(MJB);
    setWork_Tables(WT);
  }
  //----------------------------------------------

  //----------------------------------------------------
  function FindDeleteObj(array, Key, Value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        array.splice(i, 1);
      }
    }
  }
  //------------------------------------------
  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }
  //***********************
  function viewRelationship(R_Id) {
    setMainJsonBody([]);
    setWork_Tables([]);
    setNewName("");
    let WT = [];
    let MJB = [];
    setLoading(true);

    if (R_Id) {
      axios
        .get(BaseUrl + "/Relations2/GetRelationshipById/" + R_Id, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setRelationshipName(res.data.relationShipName);
          setNewName(res.data.relationShipName);
          setRelationshipId(R_Id);

          let array = res.data.tables;
          //  console.log(array);

          for (let index = 0; index < array.length; index++) {
            let tableHead = [];
            let tableHeadIds = [];
            let Sequence = 0;
            let TableName = "";
            let TableId = "";

            TableName = array[index].tableName;
            TableId = array[index].tableId;
            array[index].tableColumns &&
              array[index].tableColumns.map((item) => tableHead.push(item));
            array[index].tableColumns &&
              array[index].tableColumns.map((item) => tableHeadIds.push(item));

            setTableHead(tableHead);
            setTableHeadIds(tableHeadIds);
            if (array[index].tableColumns && TableName) {
              Sequence = MainJsonBody.length + 1;
              let obj1 = {
                tableId: TableId,
                tableName: array[index].tableName,
                columnId: array[index].columnId,
                reference: array[index].reference,
                tableColumns: array[index].tableColumns,
                Sequence: Sequence,
              };
              if (FindObj(MJB, "tableId", TableId) === false) {
                MJB.push(obj1);

                WT.push({
                  TableId: TableId,
                  TableTitle: TableName,
                  TableHeader: tableHead,
                  SelectedColumn: array[index].columnId,
                  TableHeaderIds: tableHeadIds,
                  Sequence: Sequence,
                });
              }
            }

            let a1 = array[index].reference;
            for (let i = 0; i < a1.length; i++) {
              let array1 = a1[i].target;
              for (let index = 0; index < array1.length; index++) {
                let tableHead = [];
                let tableHeadIds = [];
                let Sequence = 0;
                let TableName = "";
                let TableId = "";

                TableName = array1[index].tableName;
                TableId = array1[index].tableId;
                array1[index].tableColumns &&
                  array1[index].tableColumns.map((item) =>
                    tableHead.push(item)
                  );
                array1[index].tableColumns &&
                  array1[index].tableColumns.map((item) =>
                    tableHeadIds.push(item)
                  );

                setTableHead(tableHead);
                setTableHeadIds(tableHeadIds);
                if (array1[index].tableColumns && TableName) {
                  Sequence = MainJsonBody.length + 1;
                  let obj1 = {
                    tableId: TableId,
                    tableName: array1[index].tableName,
                    columnId: array1[index].columnId,
                    reference: array1[index].reference,
                    tableColumns: array1[index].tableColumns,
                    Sequence: Sequence,
                  };
                  if (FindObj(MJB, "tableId", TableId) === false) {
                    MJB.push(obj1);

                    WT.push({
                      TableId: TableId,
                      TableTitle: TableName,
                      TableHeader: tableHead,
                      SelectedColumn: array1[index].columnId,
                      TableHeaderIds: tableHeadIds,
                      Sequence: Sequence,
                    });
                  }
                }
              }
            }
          }
        })
        .catch((res) => {
          toast.error("Tables of this relationship are missing", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setMainJsonBody(MJB);
          setWork_Tables(WT);
          setTimeout(function () {
            setLoading(false);
          }, 1000);
          setShowLayout(true);
          setShowLinks(false);
        });
    }
  }

  //----------------------------------------------------

  useEffect(() => {
    axios
      .get(BaseUrl + "/Relations2/GetAllRelationships", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setRelationshipsList(res.data);
          setReloadList(false);
        } else {
          setRelationshipsList([]);
        }
      })
      .finally(() => {});

    axios
      .get(BaseUrl + "/Relations2/GetProjects", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setProjectsList(res.data);
        } else {
          setProjectsList([]);
        }
      })
      .finally(() => {});
  }, [newName, reload]);
  //------------------------------------------

  //----------------------------------------------------
  function AddTableToWorkPlace(TableId) {
    let tableHead = [];
    let tableHeadIds = [];
    let Sequence = 0;
    let TN = "";
    let WT = [...Work_Tables];
    let MJB = [...MainJsonBody];
    let Found = FindObj(MJB, "tableId", TableId);

    if (TableId && !Found) {
      axios
        .get(BaseUrl + "/Data/GetColumnsByTable/" + TableId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          TN = res.data.tableName;

          res.data.columns.map((item, index) => tableHead.push(item));
          res.data.columns.map((item, index) => tableHeadIds.push(item));
        })
        .finally(() => {
          setTableHead(tableHead);
          setTableHeadIds(tableHeadIds);

          Sequence = MainJsonBody.length + 1;
          MJB.push({
            tableId: TableId,
            tableName: TN,
            columnId: "TN1",
            reference: [],
            tableColumns: tableHeadIds,
            Sequence: Sequence,
          });

          setMainJsonBody(MJB);

          WT.push({
            TableId: TableId,
            TableTitle: TN,
            TableHeader: tableHead,
            TableHeaderIds: tableHeadIds,
            Sequence: Sequence,
          });
          setWork_Tables(WT);
        });
    } else if (TableId && Found) {
      toast.warning("Table is exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //----------------------------------------------------
  useEffect(() => {
    axios
      .get(BaseUrl + "/Data/GetTablesAll", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setTablesList(res.data);
      })
      .finally(() => {
        setReloadLists(false);
      });
  }, []);

  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-3 table-scroll">
            <div className="top-bg p-3 rounded-5 min-vh-100">
              <div className="row d-flex flex-column h-100">
                <div className="col-6 w-100">
                  <RelationshipList
                    renderItem={renderItem}
                    relationshipsList={relationshipsList}
                    viewRelationship={viewRelationship}
                    setReloadList={setReloadList}
                    setMainJsonBody={setMainJsonBody}
                    setWork_Tables={setWork_Tables}
                    setNewName={setNewName}
                    setRelationshipName={setRelationshipName}
                    setReload={setReload}
                    setShowLinks={setShowLinks}
                    setShowLayout={setShowLayout}
                  />
                </div>
              </div>

              {showLayout && relationshipId ? (
                <div className="row mt-4">
                  <div className="row d-flex flex-column h-100">
                    <div className="col-6 w-100">
                      <TableList
                        renderItem={renderItem}
                        tablesList={tablesList}
                        setAddTable={setAddTable}
                        CreateRelationship={true}
                        AddTableToWorkPlace={AddTableToWorkPlace}
                        defaultClose={false}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 px-0 ">
            <div className="rel-header">
              <Actions
                saveNewRelationship={saveNewRelationship}
                newName={newName}
                handleChange={handleChange}
                RelationshipId={relationshipId}
                GenerateReport
                Work_Tables={Work_Tables}
                setArrayToGenerate={setArrayToGenerate}
                MainJsonBody={MainJsonBody}
                setMainJsonBody={setMainJsonBody}
                arrayToGenerate={arrayToGenerate}
                RelationshipName={RelationshipName}
                relationshipId={relationshipId}
                setShowLinks={setShowLinks}
                setShowLayout={setShowLayout}
                showLayout={showLayout}
                resetFilter={resetFilter}
                setResetFilter={setResetFilter}
              />
            </div>

            <div className="rel-area">
              {showLayout ? (
                <RelationshipLayout
                  loadingText=""
                  loading={loading}
                  RelationshipName={RelationshipName}
                  Work_Tables={Work_Tables}
                  setMainJsonBody={setMainJsonBody}
                  MainJsonBody={MainJsonBody}
                  viewRelationship={viewRelationship}
                  removeFromRelationship={removeFromRelationship}
                  AddNew={false}
                  setNewName={setNewName}
                />
              ) : (
                <AllTablesLinksContainer
                  MainJsonBody={MainJsonBody}
                  setMainJsonBody={setMainJsonBody}
                  setShowLinks={setShowLinks}
                  showLayout={showLayout}
                  showLinks={showLinks}
                  setShowLayout={setShowLayout}
                  resetFilter={resetFilter}
                  From_ViewRelationship
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relationships;
